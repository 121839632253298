/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import sizes from 'constants/sizes';
import { cleanValue } from 'constants/formats';
import { getBeneficiaryFDPercent, getDecimalScale } from 'utils/filters';

import { setModal } from 'modules/_shared/redux/modalActions';
import BeneficiarySocietyValueSimulator from 'modules/beneficiaries/components/BeneficiarySocietyValueSimulator';
import getSimulatorValues, {
  getVestedNow,
} from 'modules/beneficiaries/components/BeneficiarySocietyValueSimulator/utils/getSimulatorValues';
import BeneficiarySell from 'modules/beneficiaries/modals/BeneficiarySell';

import { hasShowDetails } from 'utils/showDetails';
import BeneficiaryAsidePersonalSection from './components/BeneficiaryAsidePersonalSection';
import BeneficiaryAsideTotalIncentiveSection from './components/BeneficiaryAsideTotalIncentiveSection';
import BeneficiaryAsideActualVestedSection from './components/BeneficiaryAsideActualVestedSection';
import getAssignedAndTotalShares from '../BeneficiarySellInvoiceView/utils/getAssignedAndTotalShares';

function BeneficiaryAside({
  beneficiary,
  society,
  setSimulatorValue,
  beneficiaryAllPlans,
}) {
  const dispatch = useDispatch();
  const { societyValue } = beneficiary;

  const tenderOffers = useSelector((state) => state.tenderOffers);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const [simulatorSocietyValue, setSimulatorSocietyValue] = useState(
    societyValue?.value || 0
  );
  const [vestedNowUnits, setVestedNowUnits] = useState(0);
  const [priceUnitSellFD, setPriceUnitSellFD] = useState(0);
  const [exercisePriceActualConsolidated, setExercisePriceActualConsolidated] =
    useState(0);
  const [currentNetFDVestedValue, setCurrentNetFDVestedValue] = useState(0);

  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );
  const [FDpercent] = useState(
    getBeneficiaryFDPercent(beneficiary, society).toFixed(decimalScale)
  );
  const [incentiveValue] = useState(
    societyValue ? (+FDpercent * societyValue.value) / 100 : 0
  );

  const [valuation, setValuation] = useState();
  const [summary, setSummary] = useState({});

  const [currentVestedValue, setCurrentVestedValue] = useState(0.0);
  const [currentVestedDate, setCurrentVestedDate] = useState('???');

  const hasBeneficiaryTotalIncentiveSectionDisabled =
    society?.configuration?.hasBeneficiaryTotalIncentiveSectionDisabled;

  const hasBeneficiarySocietyValueSimulatorSectionDisabled =
    society?.configuration?.hasBeneficiarySocietyValueSimulatorSectionDisabled;

  const hasBeneficiaryActualVestedSectionDisabled =
    society?.configuration?.hasBeneficiaryActualVestedSectionDisabled;

  const calculateValues = () => {
    const {
      currentVestedValue: calculatedVestedValue,
      currentVestedDate: calculatedVestedDate,
      incentiveValue: calculatedIncentiveValue,
      exercisePrice: calculatedExercisePrice,
      FDpercent: calculatedFDpercent,
      summary: calculatedSummary,
    } = getSimulatorValues({ society, societyValue, beneficiaryAllPlans });

    setCurrentVestedValue(calculatedVestedValue);
    setCurrentVestedDate(calculatedVestedDate);
    setSummary(calculatedSummary);

    return {
      calculatedIncentiveValue,
      calculatedExercisePrice,
      calculatedFDpercent,
    };
  };

  const handleChangeSlider = (value) => {
    const { FDpercent: currentFD, exercisePrice } = getSimulatorValues({
      society,
      societyValue,
      beneficiaryAllPlans,
    });

    const newValue = cleanValue(value[0]);
    const valuation = (currentFD * newValue) / 100;
    const higherValue = Math.max(valuation, exercisePrice);

    setSimulatorValue(newValue);
    setSimulatorSocietyValue(newValue);
    setValuation(higherValue);
  };

  const handleOpenSellView = () => {
    dispatch(
      setModal(
        // @ts-ignore
        <BeneficiarySell
          size={sizes.XL}
          society={society}
          beneficiary={beneficiary}
          beneficiaryAllPlans={beneficiaryAllPlans}
          setSimulatorValue={setSimulatorValue}
          societyValue={societyValue}
        />
      )
    );
  };

  useEffect(() => {
    if (beneficiaryAllPlans.length) {
      calculateValues();
      const { incentiveValue } = getSimulatorValues({
        society,
        societyValue,
        beneficiaryAllPlans,
      });
      setValuation(incentiveValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStrikePrice = (beneficiary, plan) => {
    const conditions = beneficiary?.customConditions;
    if (conditions && conditions?.sharePrice) {
      return conditions?.isFixedPrice
        ? `${conditions?.sharePrice} (fijo)`
        : conditions?.sharePrice;
    }
    return plan?.isFixedPrice ? `${plan?.sharePrice} (fijo)` : plan?.sharePrice;
  };

  useEffect(() => {
    if (beneficiaryAllPlans && actualSociety?.beneficiaries?.length) {
      const currentSocietyPlans = beneficiaryAllPlans?.map(
        (beneficiaryPlan) => ({ ...beneficiaryPlan?.planData })
      );
      const currentSocietyTenderOffers = tenderOffers?.filter(
        (tenderOffer) => tenderOffer?.society === society?.societyId
      );

      const vestedNow = getVestedNow(beneficiaryAllPlans);

      const result = getAssignedAndTotalShares(
        actualSociety,
        currentSocietyPlans,
        currentSocietyTenderOffers
      );

      setPriceUnitSellFD(
        cleanValue(simulatorSocietyValue) / result?.societyShares
      );
      setVestedNowUnits(vestedNow);

      let totalValue = 0;

      beneficiaryAllPlans.forEach((beneficiary) => {
        const currentVestedUnits = getVestedNow([beneficiary]);
        const currentStrikePrice = getStrikePrice(
          beneficiary,
          beneficiary.planData
        );

        const total = (currentVestedUnits || 0) * (currentStrikePrice || 0);

        if (hasShowDetails()) {
          console.log('cclog ', {
            // eslint-disable-line
            currentVestedUnits,
            currentStrikePrice,
            total,
          });
        }

        totalValue += total;
      });

      setExercisePriceActualConsolidated(totalValue);

      // (restar las vesteadas ahora multiplicadas por su Strike Price)
    }
  }, [
    actualSociety,
    beneficiaryAllPlans,
    simulatorSocietyValue,
    society,
    tenderOffers,
  ]);

  useEffect(() => {
    const result =
      vestedNowUnits * priceUnitSellFD - exercisePriceActualConsolidated;
    setCurrentNetFDVestedValue(result);

    if (hasShowDetails()) {
      console.log('🚀 cclog ~ useEffect ~ result:', {
        _1_vestedNowUnits: vestedNowUnits,
        _2_priceUnitSellFD: priceUnitSellFD,
        _3_exercisePriceActualConsolidated: exercisePriceActualConsolidated,
        _4_result: result,
      });
    }
  }, [exercisePriceActualConsolidated, priceUnitSellFD, vestedNowUnits]);

  return (
    <>
      <BeneficiaryAsidePersonalSection beneficiary={beneficiary} />
      {incentiveValue && !hasBeneficiarySocietyValueSimulatorSectionDisabled ? (
        <BeneficiarySocietyValueSimulator
          societyValue={societyValue}
          valuation={valuation}
          onChange={handleChangeSlider}
          onClickSimulatorButton={handleOpenSellView}
        />
      ) : null}

      {!hasBeneficiaryActualVestedSectionDisabled && (
        <BeneficiaryAsideActualVestedSection
          currentVestedValue={currentVestedValue}
          currentVestedDate={currentVestedDate}
          currentNetFDVestedValue={currentNetFDVestedValue}
        />
      )}

      {!hasBeneficiaryTotalIncentiveSectionDisabled && (
        <BeneficiaryAsideTotalIncentiveSection
          beneficiary={beneficiary}
          incentiveValue={incentiveValue}
          summary={summary}
        />
      )}
    </>
  );
}

export default BeneficiaryAside;
