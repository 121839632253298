import { format } from 'date-fns';
import { enUS, es, fr } from 'date-fns/locale';

import { SharesTitle } from 'types';
import { dateFormatLong } from 'constants/formats';
import { getPartnersDataFromTitle } from 'modules/partners/pages/TitleRegistryBook/utils/getPartnersDataFromTitle';

import { formatCurrencyDecimals } from './formats';

const locales: Record<string, any> = { en: enUS, es, fr };

export const getTitlePartnerNameTag = (title: SharesTitle) => {
  const partners = getPartnersDataFromTitle(title);

  return partners[0]?.name || '';
};

export const getTitlePartnerCifTag = (title: SharesTitle) => {
  const partners = getPartnersDataFromTitle(title);

  return partners[0]?.cif || '';
};

export const getTitlePartnerCapitalTag = (title: SharesTitle) =>
  title?.sharesCapital ? formatCurrencyDecimals(title.sharesCapital) : '-';

export const getTitlePartnerDateTag = (
  title: SharesTitle,
  societyLanguage: string
) =>
  title?.createdAt
    ? format(new Date(title.createdAt), dateFormatLong, {
        locale: locales[societyLanguage],
      })
    : '[TITULO_FECHA]';

export const getTitlePartnerNumerationTag = (title: SharesTitle) =>
  title?.intervals
    ?.map((interval) => `${interval?.shareFrom} / ${interval?.shareTo}`)
    .join(', ') || '';
