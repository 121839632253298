/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable no-console */
/* eslint-disable react/jsx-curly-newline */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import EmptyStateCard from 'modules/_shared/components/EmptyStates/EmptyStateCard';
import { updateSociety } from 'modules/society/redux/societyActions';
import { hasIntegrations } from 'modules/society/utils/societyHelpers';
import { validateIntegration } from 'modules/society/redux/integrationActions';

import integrations from 'constants/integrations';
import isSuperAdmin from 'utils/isSuperAdmin';

import './SocietyIntegrations.scss';

const integrationStatus = {
  DISABLED: { name: 'Disabled', color: 'gray' },
  ENABLED: { name: 'Enabled', color: 'info' },
  CONNECTED: { name: 'Connected', color: 'success' },
};

function SocietyIntegrations() {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const society = useSelector((state) => state.society?.actualSociety);

  const [integrationKeysOriginal, setIntegrationKeysOriginal] = useState({
    factorial: '',
    personio: '',
  });

  const [integrationKeys, setIntegrationKeys] = useState({
    factorial: '',
    personio: '',
  });

  const [integrationOpen, setIntegrationOpen] = useState({
    factorial: false,
    personio: false,
  });

  const [integrationEdit, setIntegrationEdit] = useState({
    factorial: false,
    personio: false,
  });

  const getIntegrationStatus = (key, value) => {
    let status;

    if (value.enabled) {
      status = value.validated
        ? integrationStatus.CONNECTED
        : integrationStatus.ENABLED;
    } else {
      status = integrationStatus.DISABLED;
    }

    return (
      <span
        className={`badge badge-dot badge-dot-xs text-${status.color} ms-1`}
      >
        {t(status.name)}
      </span>
    );
  };

  const getMaskValue = (key, value) => {
    if (!integrationEdit[key]) {
      return value?.replace(/.(?=.{4})/g, '*');
    }
    return value;
  };

  const handleChangeKeys = (key, value) => {
    setIntegrationKeys({ ...integrationKeys, [key]: value });
  };

  const handleToggleRow = (key, value) => {
    if (value?.enabled) {
      setIntegrationOpen({ ...integrationOpen, [key]: !integrationOpen[key] });
    }
  };

  const handleSaveKey = async (key, value) => {
    try {
      const isValidKey = await validateIntegration(key, value);

      if (isValidKey) {
        dispatch(
          updateSociety(
            society._id,
            {
              integrations: {
                updateData: {
                  name: key,
                  value: { enabled: true, key: value, validated: true },
                },
              },
            },
            false
          )
        );
        setIntegrationEdit({ ...integrationEdit, [key]: false });
        return Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'APIKeyValidationSuccess'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
      }

      dispatch(
        updateSociety(
          society._id,
          {
            integrations: {
              updateData: {
                name: key,
                value: { enabled: true, key: value, validated: false },
              },
            },
          },
          false
        )
      );
      return Swal.fire({
        icon: 'error',
        title: `<h4 class="nk-modal-title">${t(
          'APIKeyValidationError'
        )}<br></h4>`,
        html: `<p class="text-soft">${t(
          'APIKeyValidationErrorDescription'
        )}</p>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditKey = (key) => {
    setIntegrationEdit({ ...integrationEdit, [key]: true });
    setIntegrationKeys({
      ...integrationKeys,
      [key]: integrationKeysOriginal[key],
    });
  };

  useEffect(() => {
    if (society?.integrations) {
      const keyValues = {};
      Object.entries(society.integrations).forEach(([key, value]) => {
        keyValues[key] = value?.key || '';
      });
      setIntegrationKeys(keyValues);
      setIntegrationKeysOriginal(keyValues);
    }
  }, [society]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">{t('Integrations')}</h5>
          <div className="nk-block-des">
            <p>{t('IntegrationsTabDescription')}</p>
          </div>
        </div>
      </div>
      <div className="card card-bordered">
        <ul className="nk-support">
          {!hasIntegrations(society) && (
            <EmptyStateCard
              mainMessage={t('ErrorNoIntegrations')}
              infoMessage={t('ErrorNoIntegrationsDescription')}
            />
          )}

          {society?.integrations &&
            Object.entries(society.integrations).map(([key, value]) => (
              <li
                key={key}
                className={`nk-support-item integration ${
                  value?.enabled || isSuperAdmin(user) ? 'enabled' : 'disabled'
                }`}
              >
                <div
                  className="integration-item"
                  onClick={() => handleToggleRow(key, value)}
                >
                  <div className="integration-logo-box">
                    <img
                      src={integrations[key]?.logo}
                      alt={integrations[key]?.name}
                    />
                  </div>

                  <div className="nk-support-content">
                    <div className="title">
                      <span>{integrations[key]?.name}</span>
                      {getIntegrationStatus(key, value)}
                    </div>
                    <p>{t(integrations[key]?.description)}</p>
                    {/* <span className="time">6 min ago</span> */}
                  </div>
                </div>

                <div
                  className={`integration-details ${
                    integrationOpen[key] ? 'd-block' : 'd-none'
                  }`}
                >
                  <div className="d-flex flex-row justify-content-center align-items-end">
                    <div className="col-md-8">
                      <div className="form-group">
                        <label className="form-label" htmlFor="display-name">
                          Introduzca API Key:
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          value={getMaskValue(key, integrationKeys[key])}
                          onChange={(event) =>
                            handleChangeKeys(key, event.target.value)
                          }
                          disabled={!integrationEdit[key]}
                          placeholder="..."
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      {integrationEdit[key] ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-lg w-100 text-center align-center"
                          onClick={() => {
                            handleSaveKey(key, integrationKeys[key]);
                          }}
                        >
                          <span>Validar y guardar</span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-lg w-100 text-center align-center"
                          onClick={() => {
                            handleEditKey(key);
                          }}
                        >
                          <span>Editar</span>
                        </button>
                      )}
                    </div>
                  </div>
                  {integrations[key]?.instructions && (
                    <div className="col-md-12 mt-4">
                      <div
                        className="alert alert-pro alert-primary bg-primary-dim"
                        dangerouslySetInnerHTML={{
                          __html: integrations[key]?.instructions,
                        }}
                      />
                    </div>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default SocietyIntegrations;
