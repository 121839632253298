/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { updateDraft } from 'modules/drafts/redux/draftActions';

import activePartner from 'utils/activePartner';
import {
  getActualSociety,
  getPartnerFutureShares,
  getSocietyTotalShares,
} from 'utils/filters';

import {
  cleanValue,
  currencyFormat,
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import menuTypes from 'constants/menuTypes';

import PrintButton from 'modules/_shared/components/Buttons/PrintButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import SaveDrafts from 'modules/_shared/components/Modals/SaveDrafts';
import DraftSellRow from 'modules/drafts/components/DraftSellRow';
import { useGetDraftsSectionPermissions } from 'modules/drafts/hooks/useGetDraftsSectionPermissions';
import SecureExcelButton from 'modules/_shared/components/Buttons/SecureExcelButton';

const draftType = 'DRAFT_SELL';

const DraftSell = () => {
  const printRef = useRef();
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId, draftId } = useParams();

  const { isAdmin, isReadOnly } = useGetDraftsSectionPermissions().data;

  const { user, drafts, actualSociety } = useSelector((state) => ({
    user: state?.user,
    actualSociety: state?.society?.actualSociety,
    drafts: state?.drafts,
  }));

  const [rows, setRows] = useState([]);
  const [rowsPrintVersion, setRowsPrintVersion] = useState([]);

  const [draft, setDraft] = useState({});
  const [draftName, setDraftName] = useState('');

  const [partners, setPartners] = useState([]);
  const [updatedPartners, setUpdatedPartners] = useState([]);

  const [valuation, setValuation] = useState(0); // Valoración Pre Money
  const [totalContribution, setTotalContribution] = useState(0); // Ampliación
  // eslint-disable-next-line no-unused-vars
  const [valuationPostMoney, setValuationPostMoney] = useState(0); // Valoración Post Money
  const [nominalValue, setNominalValue] = useState(0); // Valor nominal
  const [premium, setPremium] = useState(0); // Prima
  const [newSharesPrice, setnewSharesPrice] = useState(0); // Precio nuevas participaciones
  // eslint-disable-next-line no-unused-vars
  const [newShares, setNewShares] = useState(0); // Nuevas participaciones
  // eslint-disable-next-line no-unused-vars
  const [dilution, setDilution] = useState(0); // Dilución

  const [sellPercent, setSellPercent] = useState(100); // Porcentaje de venta
  const [sellShares, setSellShares] = useState(0); // Total de participaciones en venta
  const [sellTotalPay, setSellTotalPay] = useState(0); // Total de participaciones en venta

  // eslint-disable-next-line no-unused-vars
  const [accumulatedShares, setAccumulatedShares] = useState(0); // Acumulado de participaciones de la Sociedad

  const downloadFileName = `${draftName || t('SellParticipations')} - ${
    actualSociety?.name
  }`;

  const [touched, setTouched] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const getTotalPartnerShares = (partnersData) =>
    partnersData.map((item) => item.shares.total).reduce((a, b) => a + b, 0);

  const getTotalNewShares = (partnersData) =>
    partnersData.map((item) => item.shares?.new).reduce((a, b) => a + b, 0);

  const getTotalContribution = (partnersData) =>
    partnersData.map((item) => item.contribution).reduce((a, b) => a + b, 0);

  const getActualDraft = async (draftId) =>
    drafts.find((draft) => draft['_id'] === draftId);

  const handleSaveDraft = () => {
    if (draftId) {
      dispatch(updateDraft(draftId, draft));
      setTouched(false);
    } else {
      dispatch(
        setModal(<SaveDrafts draftData={draft} setTouched={setTouched} />)
      );
    }
  };

  const calculateGeneralData = (partnersList) => {
    if (!partnersList) return;
    const totals = partnersList.reduce(
      (a, b) => {
        a.totalSellShares += b.shares - b.remainingShares;
        a.societyShares += b.shares;
        return a;
      },
      { totalSellShares: 0, societyShares: 0 }
    );
    setSellShares(totals.totalSellShares);

    const cleanValueValuation = cleanValue(valuation);
    const currentPremium =
      cleanValueValuation / totals.societyShares - (nominalValue || 0);
    setPremium(currentPremium);
    const totalPay = totals.totalSellShares * (currentPremium + nominalValue);
    setSellTotalPay(totalPay);
    // const totalNewContribution = getTotalContribution(partnersList);
  };

  const calculatePartnersData = (partnersList) => {
    // eslint-disable-next-line prefer-const
    const updatedPartners = [];

    partnersList.forEach((partner) => {
      const cleanValueValuation = cleanValue(valuation);
      const societyActualShares = actualSociety?.sharesCount?.actual;
      const partnerNewShares = Math.floor(
        partner.contribution / +(cleanValueValuation / societyActualShares)
      );
      const partnerTotalShares = partnerNewShares + partner?.shares?.initial;

      const currentPremium =
        cleanValue(valuation) / societyActualShares - +nominalValue;
      const partnerContribution =
        partnerNewShares * (nominalValue + currentPremium);
      const partnerPercent =
        (partnerContribution / cleanValue(valuation)) * 100;

      updatedPartners.push({
        ...partner,
        contribution: +partnerContribution.toFixed(2),
        NDPercent: +partnerPercent,
        shares: {
          ...partner.shares,
          new: +partnerNewShares,
          total: partnerTotalShares,
        },
      });

      setPremium(currentPremium);
      setAccumulatedShares(societyActualShares);
      setNewShares(getTotalNewShares(updatedPartners));
    });

    const accumulatedShares =
      actualSociety?.sharesCount?.actual + getTotalNewShares(updatedPartners);
    setAccumulatedShares(accumulatedShares);

    const updatedNDPartners = updatedPartners.map((partner) => ({
      ...partner,
      NDTotal: +((partner.shares.total * 100) / accumulatedShares).toFixed(2),
    }));

    const totalNewContribution = getTotalContribution(updatedPartners);
    setTotalContribution(totalNewContribution);
    setDilution(
      +((+totalNewContribution * 100) / cleanValue(valuation)).toFixed(2)
    );

    return updatedNDPartners;
  };

  const loadInitialValuesWithPartners = async (draftId) => {
    try {
      const tempDraft = await getActualDraft(draftId);
      if (tempDraft) {
        const currentPartners = tempDraft?.draftPartners;
        setDraftName(tempDraft.name);
        setValuation(tempDraft.valuation);
        setSellPercent(tempDraft.percent);
        setUpdatedPartners(currentPartners);
      } else {
        history.push(`/borradores/${societyId}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error loading draft data', error);
    }
  };

  const loadPartnerValuesFromSociety = (currentSociety) => {
    const currentSocietyActualShares = currentSociety?.sharesCount?.actual;
    const currentSocietyTotalShares = getSocietyTotalShares(currentSociety);
    const filteredPartners = currentSociety?.partners?.filter((partner) =>
      activePartner(partner)
    );
    const partnersData = filteredPartners.map((partner) => {
      const partnerActualShares = partner?.sharesCount?.actual;
      const partnerFutureShares = getPartnerFutureShares(
        partner['_id'],
        currentSociety
      );
      return {
        name: partner?.name,
        email: partner?.email,
        society: currentSociety['_id'],
        societyPartnerId: partner['_id'],
        shares: partnerActualShares,
        NDPercent: +(
          (partnerActualShares * 100) /
          currentSocietyActualShares
        ).toFixed(2),
        FDPercent: +(
          (partnerFutureShares * 100) /
          currentSocietyTotalShares
        ).toFixed(2),
        NDValue:
          (+(
            ((partnerActualShares * 100) / currentSocietyActualShares) *
            cleanValue(valuation)
          ) /
            100) *
          (cleanValue(sellPercent) / 100),
        FDValue:
          (+(
            ((partnerFutureShares * 100) / currentSocietyTotalShares) *
            cleanValue(valuation)
          ) /
            100) *
          (cleanValue(sellPercent) / 100),
        remainingShares: Math.floor(
          partnerActualShares -
            partnerActualShares * (cleanValue(sellPercent) / 100) || 0
        ),
        remainingNDPercent:
          +((partnerActualShares * 100) / currentSocietyActualShares).toFixed(
            2
          ) *
          ((100 - cleanValue(sellPercent)) / 100),
      };
    });

    calculateGeneralData(partnersData);
    setUpdatedPartners(partnersData);
  };

  // eslint-disable-next-line no-unused-vars
  const setInitialValues = () => {
    setRows([]);
    setPartners([]);
    setUpdatedPartners([]);
    setValuation(0);
    setTotalContribution(0);
    setValuationPostMoney(0);
    setNominalValue(actualSociety?.nominalValue);
    setPremium(0);
    setNewShares(0);
    setDilution(0);
  };

  const handleChangeSellPercent = (e) => {
    const numberValue = Number(e.target.value);

    if (numberValue >= 0 && numberValue <= 100) {
      setSellPercent(e.target.value);
      setTouched(true);
    }
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setSellPercent(100);
      setNominalValue(actualSociety?.nominalValue);
      loadPartnerValuesFromSociety(actualSociety);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (draftId) {
      loadInitialValuesWithPartners(draftId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (partners.length > 0) {
      // calculateGeneralData(partners);
      if (actualSociety) {
        setUpdatedPartners(calculatePartnersData(partners));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners]);

  useEffect(() => {
    setValuationPostMoney(cleanValue(valuation) + +totalContribution);
  }, [valuation, totalContribution]);

  useEffect(() => {
    if (
      actualSociety &&
      valuation &&
      sellPercent &&
      updatedPartners.length > 0
    ) {
      loadPartnerValuesFromSociety(actualSociety);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuation, sellPercent]);

  useEffect(() => {
    if (updatedPartners && updatedPartners.length > 0) {
      setRows(
        updatedPartners?.map((partner, index) => (
          <DraftSellRow
            key={partner.societyPartnerId}
            index={index + 1}
            societyId={actualSociety['_id']}
            draft={partner}
            premium={premium}
            valuation={valuation}
            partner={partner}
          />
        ))
      );
      setDraft({
        draftType,
        percent: cleanValue(sellPercent),
        valuation: cleanValue(valuation),
        totalContribution: cleanValue(sellTotalPay),
        society: actualSociety['_id'],
        draftPartners: JSON.stringify(
          updatedPartners.map((partner) => ({
            name: partner.name,
            email: partner.email,
            society: partner.society,
            societyPartnerId: partner.societyPartnerId,
          }))
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPartners]);

  useEffect(() => {
    if (nominalValue && premium) setnewSharesPrice(nominalValue + premium);
  }, [nominalValue, premium]);

  useEffect(() => {
    setRowsPrintVersion(
      rows.map(({ props }) => (
        <DraftSellRow
          print
          key={props.partner.societyPartnerId}
          index={props.index}
          societyId={actualSociety['_id']}
          draft={props.partner}
          premium={props.premium}
          valuation={props.valuation}
        />
      ))
    );
  }, [rows]); // eslint-disable-line

  return actualSociety ? (
    <>
      <Prompt
        message={`${t('Hello')}\n ${t('DraftDontSaved')}`}
        when={touched && !isReadOnly}
      />
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">{t('PlanSale')}</h3>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <>
                          <li>
                            <SecureExcelButton
                              tableId="sell-participations-table"
                              label={t('DownloadXLS')}
                              fileName={downloadFileName}
                              sheetName={t('SellParticipations')}
                            />
                          </li>
                          <li>
                            <PrintButton
                              fileName={downloadFileName}
                              printRef={printRef}
                              hideLabel
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* HEADER */}
          <div ref={printRef} id="margin-print-only">
            <PrintHeader title={t('PrintHeaderDraftSell')} />

            <div className="card card-bordered sp-plan">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="sp-plan-info card-inner">
                    <div className="row gx-0 gy-3">
                      <div className="col-xl-9 col-sm-8">
                        <div className="sp-plan-name">
                          <h6 className="title">
                            {t('SaleData')}
                            <span className="badge badge-success badge-pill">
                              {t('Draft')}
                            </span>
                          </h6>
                          <h5 className="sub-title text-primary">
                            {draftId && draftName}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="invest-field form-group">
                      <div className="form-label-group" />
                    </div>
                    <div className="row gy-4">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="default-01">
                            {t('CompanyValuation')}
                          </label>
                          <div className="form-control-wrap">
                            <NumberFormat
                              id="outlined-normal"
                              className="form-control form-control-xl form-control-outlined"
                              value={valuation}
                              onChange={(e) => {
                                setValuation(e.target.value);
                                setTouched(true);
                              }}
                              {...numberFormatDecimals}
                            />
                            <div className="form-info">€</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="default-01">
                            {t('PercentageSold')}
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              className="form-control form-control-xl form-control-outlined"
                              id="outlined-normal"
                              value={sellPercent}
                              onChange={handleChangeSellPercent}
                              min="0"
                              step="0.01"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sp-plan-desc card-inner">
                    <ul className="row gx-1 text-center align-items-end">
                      <li className="col-6 col-lg-2">
                        <p>
                          <span className="text-soft">{t('Valuation')}</span>
                          <NumberFormat
                            value={valuation}
                            displayType="text"
                            {...currencyFormat}
                          />
                        </p>
                      </li>
                      <li className="col-6 col-lg-2">
                        <p>
                          <span className="text-soft">{t('NrPart.')}</span>
                          <NumberFormat
                            value={sellShares}
                            displayType="text"
                            {...numberFormat}
                          />
                        </p>
                      </li>
                      <li className="col-6 col-lg-2">
                        <p>
                          <span className="text-soft">{t('VNom')}</span>
                          <NumberFormat
                            value={nominalValue}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                      </li>
                      <li className="col-6 col-lg-2">
                        <p>
                          <span className="text-soft">{t('Premium')}</span>
                          <NumberFormat
                            value={premium}
                            displayType="text"
                            {...currencyFormatDecimals}
                            decimalScale={7}
                          />
                        </p>
                      </li>
                      <li className="col-6 col-lg-2">
                        <p>
                          <span className="text-soft">{t('PricePart')}</span>
                          <NumberFormat
                            value={newSharesPrice}
                            displayType="text"
                            {...currencyFormatDecimals}
                            decimalScale={7}
                          />
                        </p>
                      </li>
                      <li className="col-6 col-lg-2">
                        <p>
                          <span className="text-soft">{t('TotalToPay')}</span>
                          <NumberFormat
                            value={sellTotalPay}
                            displayType="text"
                            {...currencyFormatDecimals}
                            decimalScale={7}
                          />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="sp-plan-action card-inner">
                    <div className="pricing-head w-100">
                      <div className="pricing-title mb-4">
                        <h4 className="card-title title text-center">
                          {t('Valuation')}:
                          <br />
                          <NumberFormat
                            value={valuation}
                            displayType="text"
                            {...currencyFormat}
                          />
                          <span className="sub-text">
                            {t('TotalValueOfSociety')}
                          </span>
                        </h4>
                      </div>
                      <div className="card-text mb-4">
                        <div className="row">
                          <div className="col-6 text-center">
                            <span className="h5 fw-500">
                              <NumberFormat
                                value={sellPercent || '0,00'}
                                displayType="text"
                                suffix="%"
                                {...numberFormatDecimals}
                              />
                            </span>
                            <span className="sub-text">{t('%Sold')}</span>
                          </div>
                          <div className="col-6 text-center">
                            <span className="h5 fw-500">
                              <NumberFormat
                                value={newSharesPrice}
                                displayType="text"
                                {...currencyFormatDecimals}
                              />
                            </span>
                            <span className="sub-text">{t('PricePart.')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nk-block-head-content">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSaveDraft}
                        disabled={!touched || isReadOnly}
                      >
                        {t('SaveDraft')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* LIST */}
            <div className="invest-field form-group">
              <div className="form-label-group">
                <label className="form-label" />
              </div>
              <div className="nk-block nk-block-lg">
                <div className="card card-preview">
                  <div className="card-inner px-0">
                    <table
                      className="datatable-init nk-tb-list nk-tb-ulist"
                      data-auto-responsive="false"
                    >
                      <thead>
                        <tr className="nk-tb-item nk-tb-head">
                          <th className="nk-tb-col">
                            <span className="sub-text">{t('Nr')}</span>
                          </th>
                          <th className="nk-tb-col text-center">
                            <span className="sub-text">{t('Partner')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('Shares')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('%ND')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('%AD')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('NdValue')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('ADValue')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">
                              {t('Part.Remaining')}
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('RemainingNd')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </table>

                    {/* PRINT VERSION DRAFT TABLE */}

                    <table
                      className="datatable-init nk-tb-list nk-tb-ulist d-none"
                      data-auto-responsive="false"
                      id="sell-participations-table"
                    >
                      <thead>
                        <tr className="nk-tb-item nk-tb-head">
                          <th className="nk-tb-col">
                            <span className="sub-text">{t('Nr')}</span>
                          </th>
                          <th className="nk-tb-col text-center">
                            <span className="sub-text">{t('Partner')}</span>
                          </th>
                          <th className="nk-tb-col text-center">
                            <span className="sub-text">{t('Email')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('Shares')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('%ND')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('%AD')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('NdValue')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('ADValue')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">
                              {t('Part.Remaining')}
                            </span>
                          </th>
                          <th className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text">{t('RemainingNd')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{rowsPrintVersion}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default DraftSell;
