import { ChangeEvent, FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';
import languages from 'constants/languages';

type DownloadLanguageModalBodyProps = {
  lang: string;
  onChangeLang: (lang: string) => void; // eslint-disable-line no-unused-vars
};

const DownloadLanguageModalBody: FC<DownloadLanguageModalBodyProps> = ({
  lang,
  onChangeLang,
}) => {
  const { t } = useTranslate();

  const handleChangeLanguage = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeLang(e.target.value);
  };

  return (
    <Modal.Body>
      <div className="form-group">
        <label className="form-label w-100" htmlFor="realty-license-date">
          {t('SelectFormat')}
        </label>

        <ul className="row g-3">
          <li className="col-6">
            <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full border rounded py-3 w-100">
              <input
                type="radio"
                className="custom-control-input"
                name="download-formats"
                id="download-format-en"
                value={languages.en.value}
                checked={lang === languages.en.value}
                onChange={handleChangeLanguage}
              />
              <label
                className="custom-control-label ml-2"
                htmlFor="download-format-en"
              >
                <span className="d-flex flex-column text-center pl-0 pr-3">
                  <span className="lead-text mb-1 text-left">
                    Formato EN
                    <span className="ml-1 text-soft fs-11px">
                      (Decimales con punto)
                    </span>
                  </span>
                  <span className="excel-cell-numbers">1234.56</span>
                </span>
              </label>
            </div>
          </li>

          <li className="col-6">
            <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full border rounded py-3 w-100">
              <input
                type="radio"
                className="custom-control-input"
                name="download-formats"
                id="download-format-es"
                value={languages.es.value}
                checked={lang === languages.es.value}
                onChange={handleChangeLanguage}
              />
              <label
                className="custom-control-label ml-2"
                htmlFor="download-format-es"
              >
                <span className="d-flex flex-column text-center pl-0 pr-3">
                  <span className="lead-text mb-1 text-left">
                    Formato ES
                    <span className="ml-1 text-soft fs-11px">
                      (Decimales con coma)
                    </span>
                  </span>

                  <span className="excel-cell-numbers">1234,56</span>
                </span>
              </label>
            </div>
          </li>
        </ul>
      </div>
    </Modal.Body>
  );
};

export default DownloadLanguageModalBody;
