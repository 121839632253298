import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';

type DownloadLanguageModalHeaderProps = {
  handleCloseModal: () => void;
};

const DownloadLanguageModalHeader: FC<DownloadLanguageModalHeaderProps> = ({
  handleCloseModal,
}) => {
  const { t } = useTranslate();

  return (
    <Modal.Header>
      <div className="row">
        <h5 className="title col-12">{t('DownloadPartnerBook')}</h5>
      </div>
      <a className="close cursor-pointer" onClick={handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default DownloadLanguageModalHeader;
