/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getBeneficiariesConsolidationPeriods } from 'utils/consolidationPeriods';
import {
  getActualSociety,
  getBeneficiariesSummaryByPeriod,
  getPlansSummaryByPeriod,
} from 'utils/filters';
import { hasInternalView } from 'utils/showDetails';

import operationTypes from 'constants/operationTypes';
import periodTypes from 'constants/periodTypes';

import ToggleWidthButton from 'modules/_shared/components/Buttons/ToggleWidthButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';

import { useTranslate } from 'hooks/useTranslate';
import {
  getBeneficiariesMixedSummary,
  getBeneficiariesSummary,
  getPlansSummary,
} from './utils';
import modeOptions from './utils/modeOptions';
import viewOptions from './utils/viewOptions';

import PlanSummaryBeneficiaryMainTable from './components/PlanSummaryBeneficiaryMainTable';
import PlanSummaryBeneficiaryPeriodsTable from './components/PlanSummaryBeneficiaryPeriodsTable';
import PlanSummaryMainTable from './components/PlanSummaryMainTable';
import PlanSummaryPeriodsTable from './components/PlanSummaryPeriodsTable';

import './PartnerBook.scss';

const buttons = {
  plansPeriods: 'plansPeriodsBtn',
  plansSummary: 'plansSummaryBtn',
  beneficiariesPeriods: 'beneficiariesPeriodsBtn',
  beneficiariesSummary: 'beneficiariesSummaryBtn',
};

function PlanSummary() {
  const { t, i18n } = useTranslate();
  const { societyId } = useParams();

  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const tenderOffers = useSelector((state) => state.tenderOffers);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [consolidationPeriods, setConsolidationPeriods] = useState();
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  // objects for plans summary
  const [summary, setSummary] = useState();
  const [plansNames, setPlansNames] = useState();
  const [values, setValues] = useState();
  const [periods, setPeriods] = useState();
  // objects for beneficiaries summary
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesSummary, setBeneficiariesSummary] = useState();
  const [beneficiariesNames, setBeneficiariesNames] = useState();
  const [beneficiariesValues, setBeneficiariesValues] = useState();
  const [beneficiariesPeriods, setBeneficiariesPeriods] = useState();

  const [view, setView] = useState(2);
  const [mode, setMode] = useState(0);

  const [currentViewOptions, setCurrentViewOptions] = useState(
    viewOptions(i18n)
  );
  const [currentModeOptions, setCurrentModeOptions] = useState(
    modeOptions(i18n)
  );

  const handleExportTable = (mode, view) => {
    let button;
    if (mode === 0) {
      button = view < 2 ? buttons.plansPeriods : buttons.plansSummary;
    } else if (mode === 1) {
      button =
        view < 2 ? buttons.beneficiariesPeriods : buttons.beneficiariesSummary;
    } else if (mode === 2) {
      button =
        view < 2 ? buttons.beneficiariesPeriods : buttons.beneficiariesSummary;
    }
    const btn = document.getElementById(button);
    if (btn) {
      btn.click();
    }
  };

  const getSummaryByPeriod = (mode, period) => {
    if (mode === 0) {
      return getPlansSummaryByPeriod(
        beneficiaries,
        plans,
        consolidationPeriods,
        tenderOffers,
        period
      );
    }
    return getBeneficiariesSummaryByPeriod(
      beneficiaries,
      plans,
      consolidationPeriods,
      tenderOffers,
      period
    );
  };

  const getSummary = (mode) => {
    if (mode === 0) {
      return getPlansSummary({
        beneficiaries,
        plans,
        consolidationPeriods,
        tenderOffers,
        endDate: new Date(endDate),
      });
    }
    if (mode === 1) {
      return getBeneficiariesMixedSummary({
        beneficiaries,
        plans,
        consolidationPeriods,
        tenderOffers,
        endDate: new Date(endDate),
      });
    }
    if (mode === 2) {
      return getBeneficiariesSummary({
        beneficiaries,
        plans,
        consolidationPeriods,
        tenderOffers,
        endDate: new Date(endDate),
      });
    }
  };

  const getActualSummary = () => {
    let currentSummary;

    if (view === 0) {
      currentSummary = getSummaryByPeriod(mode, periodTypes.QUARTERLY.value);
    } else if (view === 1) {
      currentSummary = getSummaryByPeriod(mode, periodTypes.ANUALLY.value);
    } else if (view === 2) {
      currentSummary = getSummary(mode);
    }

    return currentSummary;
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (plans?.length) {
      const names = {};
      plans.forEach((plan) => {
        names[plan['_id']] = plan?.name;
      });
      setPlansNames(names);
    }
  }, [plans]);

  useEffect(() => {
    if (actualSociety?.beneficiaries?.length) {
      const names = {};
      actualSociety?.beneficiaries?.forEach((beneficiary) => {
        names[beneficiary['_id']] = beneficiary?.name;
      });
      setBeneficiariesNames(names);
    }

    const nonDraftBeneficiaries =
      actualSociety?.beneficiaries?.filter(
        (beneficiary) => !beneficiary?.isDraft
      ) || [];
    setBeneficiaries(nonDraftBeneficiaries);
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety && plans?.length) {
      const beneficiariesConsolidationPeriods =
        getBeneficiariesConsolidationPeriods(beneficiaries, plans);
      setConsolidationPeriods(beneficiariesConsolidationPeriods);
    }
  }, [actualSociety, plans, beneficiaries]);

  useEffect(() => {
    // Prepare data to render corresponding table
    if (
      actualSociety &&
      beneficiaries.length &&
      plans.length &&
      tenderOffers &&
      consolidationPeriods
    ) {
      const actualSummary = getActualSummary();

      // Set values and periods objects
      if (view < 2) {
        const values = Object.keys(actualSummary).reduce((acc, id) => {
          acc[id] = Object.values(actualSummary[id]).reduce(
            (acc, curr) => [...acc, ...Object.values(curr)],
            []
          );
          return acc;
        }, {});

        if (mode === 0) setValues(values);
        else setBeneficiariesValues(values);

        if (Object.keys(actualSummary).length) {
          const firstKey = Object.keys(actualSummary)[0];
          const firstElem = actualSummary[firstKey];
          const actualPeriods = Object.keys(firstElem);

          if (mode === 0) {
            setPeriods(actualPeriods);
          } else {
            setBeneficiariesPeriods(actualPeriods);
          }
        }
      }

      if (mode === 0) {
        const summaryByType = Object.keys(actualSummary).reduce(
          (acc, planId) => {
            const plan = plans.find((p) => p['_id'] === planId);

            if (plan.planType === operationTypes.STOCK_OPTION) {
              acc.stockOptions[planId] = actualSummary[planId];
            } else if (plan.planType === operationTypes.PHANTOM_SHARE) {
              acc.phantoms[planId] = actualSummary[planId];
            } else if (plan.planType === operationTypes.WARRANT) {
              acc.warrants[planId] = actualSummary[planId];
            }
            return acc;
          },
          { phantoms: {}, stockOptions: {}, warrants: {} }
        );
        setSummary(summaryByType);
      } else {
        setBeneficiariesSummary(actualSummary);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    view,
    endDate,
    mode,
    plans,
    beneficiaries,
    tenderOffers,
    actualSociety,
    consolidationPeriods,
  ]);

  useEffect(() => {
    setCurrentViewOptions(viewOptions(i18n));
    setCurrentModeOptions(modeOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  if (hasInternalView()) {
    console.log('cclog', { mode, view, summary, values, periods }); // eslint-disable-line no-console
    console.log(
      'cclog Beneficiaries',
      actualSociety?.beneficiaries?.length,
      'VS',
      beneficiaries?.length,
      'No Draft'
    );
  }

  return actualSociety ? (
    <>
      <div
        className="nk-content-body mr-0 mt-4"
        style={{
          width: 'calc(100%)',
        }}
      >
        <div className="nk-content-wrap w-100">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {mode === 0 ? t('PlansSummary') : t('BeneficiariesSummary')}
                </h3>
              </div>
              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <li>
                          <ReactHTMLTableToExcel
                            id="plansPeriodsBtn"
                            className="d-none"
                            table="periodsTable"
                            filename={`${t('PlansSummary')} - ${
                              actualSociety?.name
                            }`}
                            sheet="Resumen Planes Incentivos"
                            buttonText={`${t('Download')}`}
                            buttonComponent={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <button
                                type="button"
                                className="btn btn-white btn-outline-light"
                              >
                                <em className="icon ni ni-file-download" />
                              </button>
                            }
                          />
                          <ReactHTMLTableToExcel
                            id="plansSummaryBtn"
                            className="d-none"
                            table="summaryTable"
                            filename={`${t('PlansSummary')} - ${
                              actualSociety?.name
                            }`}
                            sheet="Resumen Planes Incentivos"
                            buttonText={`${t('Download')}`}
                            buttonComponent={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <button
                                type="button"
                                className="btn btn-white btn-outline-light"
                              >
                                <em className="icon ni ni-file-download" />
                              </button>
                            }
                          />

                          <ReactHTMLTableToExcel
                            id="beneficiariesPeriodsBtn"
                            className="d-none"
                            table="beneficiaryPeriodsTable"
                            filename={`${t('BeneficiariesSummary')} - ${
                              actualSociety?.name
                            }`}
                            sheet="Resumen Beneficiarios"
                            buttonText={`${t('Download')}`}
                            buttonComponent={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <button
                                type="button"
                                className="btn btn-white btn-outline-light"
                              >
                                <em className="icon ni ni-file-download" />
                              </button>
                            }
                          />

                          <ReactHTMLTableToExcel
                            id="beneficiariesSummaryBtn"
                            className="d-none"
                            table="beneficiarySummaryTable"
                            filename={`${t('BeneficiariesSummary')} - ${
                              actualSociety?.name
                            }`}
                            sheet="Resumen Beneficiarios"
                            buttonText={`${t('Download')}`}
                            buttonComponent={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <button
                                type="button"
                                className="btn btn-white btn-outline-light"
                              >
                                <em className="icon ni ni-file-download" />
                              </button>
                            }
                          />
                        </li>
                      )}
                      <li className="preview-item">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleExportTable(mode, view)}
                        >
                          {t('Download')}
                        </button>
                      </li>
                      <li className="preview-item">
                        <OperationMenuDots
                          title={t('View')}
                          menuOptions={currentModeOptions}
                          params={{
                            setMode,
                          }}
                        />
                      </li>
                      <li className="preview-item">
                        <OperationMenuDots
                          title="Story"
                          menuOptions={currentViewOptions}
                          params={{
                            setView,
                          }}
                        />
                      </li>
                      {view === 2 && (
                        <li className="preview-item">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                className="form-control date-picker"
                                value={endDate}
                                disabled={view !== 2}
                                onChange={(event) =>
                                  setEndDate(event.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                        </li>
                      )}

                      <li className="preview-item">
                        <ToggleWidthButton />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mode = 0 => Plans view */}
          {/* Mode = 1 => Beneficiaries view */}
          {/* Mode = 2 => Beneficiaries & Plans view */}

          {/* View = 0 => Quarterly */}
          {/* View = 1 => Yearly */}
          {/* View = 2 => Summary */}

          {mode === 0 && view < 2 && summary && values && periods && (
            <PlanSummaryPeriodsTable
              view={view}
              summary={summary}
              plansNames={plansNames}
              periods={periods}
              values={values}
            />
          )}

          {mode === 0 && view === 2 && summary && (
            <PlanSummaryMainTable
              view={view}
              summary={summary}
              plansNames={plansNames}
            />
          )}

          {(mode === 1 || mode === 2) &&
            view < 2 &&
            beneficiariesSummary &&
            beneficiariesValues &&
            beneficiariesPeriods && (
              <PlanSummaryBeneficiaryPeriodsTable
                view={view}
                summary={beneficiariesSummary}
                names={beneficiariesNames}
                periods={beneficiariesPeriods}
                values={beneficiariesValues}
              />
            )}

          {(mode === 1 || mode === 2) && view === 2 && beneficiariesSummary && (
            <PlanSummaryBeneficiaryMainTable
              view={view}
              summary={beneficiariesSummary}
              names={beneficiariesNames}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default PlanSummary;
