import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import DownloadLanguageModalHeader from './DownloadLanguageModalHeader';
import DownloadLanguageModalFooter from './DownloadLanguageModalFooter';
import DownloadLanguageModalBody from './DownloadLanguageModalBody';

type DownloadLanguageModalProps = {
  buttonId: string;
};

const DOWNLOAD_LANG_FF = 'downloadLang';

const DownloadLanguageModal: FC<DownloadLanguageModalProps> = ({
  buttonId,
}) => {
  const { i18n } = useTranslate();
  const dispatch = useDispatch();

  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCloseModal = async () => {
    await dispatch(setModal(null));
  };

  const handleDownloadTable = async () => {
    try {
      setIsDownloading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const originalLang = i18n.language;
      localStorage.setItem(DOWNLOAD_LANG_FF, selectedLang);

      i18n.changeLanguage(selectedLang);
      const downloadButton = document.getElementById(buttonId);

      if (downloadButton) {
        await downloadButton?.click();
      }

      setTimeout(() => {
        i18n.changeLanguage(originalLang);
      }, 500);

      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsDownloading(false);
      handleCloseModal();
    } catch (error) {
      setIsDownloading(false);
      handleCloseModal();
    }
  };

  useEffect(() => {
    const storedLang = localStorage.getItem(DOWNLOAD_LANG_FF);
    if (storedLang) {
      setSelectedLang(storedLang);
    }
  }, []);

  return (
    <>
      <DownloadLanguageModalHeader handleCloseModal={handleCloseModal} />
      <DownloadLanguageModalBody
        lang={selectedLang}
        onChangeLang={setSelectedLang}
      />
      <DownloadLanguageModalFooter handleDownloadTable={handleDownloadTable} />

      {isDownloading && <CustomLoading />}
    </>
  );
};

export default DownloadLanguageModal;
