import { FC } from 'react';

import { Document, Tags } from 'types';

import DownloadDocument from './components/DownloadDocument';
import DocumentSpinner from './components/DocumentsSpinner';
import DocumentsOptionsList from './components/DocumentsOptionsList';

type Props = {
  index: number;
  documentsCount: number;
  documents: Document[];
  hideOnMobile?: boolean;
  tagsObject?: Tags;
  isLandscape?: boolean;
};

const DocumentsColumn: FC<Props> = ({
  index,
  documentsCount,
  documents,
  hideOnMobile = true,
  tagsObject,
  isLandscape = false,
}) => {
  const showLoadedDocuments = () => {
    if (documents?.length === 1) {
      return (
        <DownloadDocument
          document={documents[0]}
          tagsObject={tagsObject}
          isLandscape={isLandscape}
        />
      );
    }
    if (documentsCount > 1) {
      if (documents.length === 1) {
        return <DocumentSpinner />;
      }
      return <DocumentsOptionsList documents={documents} />;
    }
    return <span>-</span>;
  };
  return (
    <td
      className={`nk-tb-col ${hideOnMobile ? 'tb-col-xl' : ''} text-center`}
      key={`member-documents-${index}`}
    >
      {documentsCount > 0 ? (
        showLoadedDocuments()
      ) : (
        <span className="btn btn-icon disabled">
          <em className="icon ni ni-file-download" />
        </span>
      )}
    </td>
  );
};

export default DocumentsColumn;
