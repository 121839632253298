import { useTranslate } from 'hooks/useTranslate';
import IconInfo from 'modules/_shared/components/Icon/IconTooltip';
import { FC } from 'react';
import { Beneficiary, Society } from 'types';
import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimalsLong,
} from 'utils/formats';

type BeneficiarySocietyMetricsProps = {
  beneficiary: Beneficiary;
  summary: {
    units: number;
    vestedUnits: number;
    FDpercent: number;
    exercisePrice: number;
  };
  society: Partial<Society>;
  decimalScale: number;
  onChangePage: (page: number) => void; // eslint-disable-line
};

const getTotalItems = (actualSociety: Partial<Society>) => {
  let totalItems = 4;
  const { hasBeneficiaryPercentDisabled, hasBeneficiaryStrikeDisabled } =
    actualSociety?.configuration || {};

  if (hasBeneficiaryPercentDisabled) {
    totalItems -= 1;
  }

  if (hasBeneficiaryStrikeDisabled) {
    totalItems -= 1;
  }

  return totalItems;
};

const BeneficiarySocietyMetrics: FC<BeneficiarySocietyMetricsProps> = ({
  beneficiary,
  summary,
  decimalScale,
  society,
  onChangePage,
}) => {
  const { t } = useTranslate();

  const cardStyles = {
    4: 'col-6 col-lg-3 mb-4 mb-lg-0',
    3: 'col-6 col-lg-4 mb-4 mb-lg-0',
    2: 'col-6 col-lg-6 mb-4 mb-lg-0',
    1: 'col-12 col-lg-12 mb-4 mb-lg-0',
  };

  const totalItems = getTotalItems(society);

  return (
    <div className="card-inner">
      <h6 className="overline-title-alt mb-2">
        {t('ParticipationOfFullnameIn', {
          fullName: beneficiary?.name,
          societyName: society?.name,
        })}
      </h6>

      <div className="row text-center">
        <div
          className={`${
            cardStyles[totalItems as keyof typeof cardStyles]
          } cursor-pointer`}
          onClick={() => onChangePage(2)}
        >
          <div className="profile-balance-amount">
            <div className="number">{formatNumber(summary?.units) || 0}</div>
          </div>
          <div className="profile-stats">
            <span className="badge btn-outline-primary ">
              {t('TotalUnitsGranted')}
            </span>
          </div>
        </div>

        <div
          className={`${
            cardStyles[totalItems as keyof typeof cardStyles]
          } cursor-pointer`}
          onClick={() => onChangePage(3)}
        >
          <div className="profile-balance-amount">
            <div className="number">
              {formatNumber(summary?.vestedUnits) || 0}
            </div>
          </div>
          <div className="profile-stats ">
            <span className="badge btn-outline-success">
              {t('UnitsVested')}
            </span>
          </div>
        </div>

        {!society?.configuration?.hasBeneficiaryPercentDisabled && (
          <div className={cardStyles[totalItems as keyof typeof cardStyles]}>
            <div className="profile-balance-amount">
              <div className="number">
                {formatNumberDecimalsLong(
                  +(summary?.FDpercent ?? 0).toFixed(decimalScale)
                )}
                %
              </div>
            </div>
            <div className="profile-stats">
              <span className="badge badge-outline">{t('Percentage')}</span>
            </div>
          </div>
        )}

        {!society?.configuration?.hasBeneficiaryStrikeDisabled && (
          <div className={cardStyles[totalItems as keyof typeof cardStyles]}>
            <div className="profile-balance-amount">
              <div className="number">
                {formatCurrencyDecimals(summary?.exercisePrice) || 0}
              </div>
            </div>
            <div className="profile-stats">
              <div className="d-flex">
                <span className="badge badge-outline">
                  {t('ExercisePrice')}
                </span>
                <IconInfo
                  id="beneficiary-details-exercise-price-tooltip"
                  tooltip={t('ExercisePriceTooltip')}
                  place="bottom"
                  className="text-left"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BeneficiarySocietyMetrics;
