/* eslint-disable no-underscore-dangle */
/* eslint-disable new-cap */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
import Decimal from 'decimal.js';
import _, { cloneDeep } from 'lodash';
import { all, create } from 'mathjs';
import percentRound from 'percent-round';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getDrafts, updateDraft } from 'modules/drafts/redux/draftActions';
import PartnerBook from 'modules/partners/pages/PartnerBook';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import convertibleNoteStatus from 'constants/convertibleNoteStatus';
import { MAX_PARTNERS_CAPTABLE } from 'constants/defaultConstants';
import draftTypes from 'constants/draftTypes';
import {
  cleanValue,
  currencyFormat,
  currencyFormatDecimals,
  dateFormat,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import menuTypes from 'constants/menuTypes';
import sizes from 'constants/sizes';

import downloadWord from 'utils/downloadWord';
import {
  getActualSociety,
  getInvestorShares,
  getPartnerActualShares,
  getPartnerFutureShares,
  getSocietyActualShares,
  getSocietyTotalShares,
} from 'utils/filters';

import PrintButton from 'modules/_shared/components/Buttons/PrintButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import AddPartnerDraftModal from 'modules/_shared/components/Modals/AddPartnerDraft';
import SaveDrafts from 'modules/_shared/components/Modals/SaveDrafts';
import DraftBanner from 'modules/drafts/components/DraftBanner';
import DraftCapitalIncreaseRow from 'modules/drafts/components/DraftCapitalIncreaseRow';

import operationTypes from 'constants/operationTypes';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import { getDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';
import cleanHtmlEntities from 'utils/editor';

import draftTemplates from 'constants/draftTemplates';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import DraftToCapitalIncreaseModal from 'modules/_shared/components/Modals/DraftToCapitalIncrease';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { useGetDraftsSectionPermissions } from 'modules/drafts/hooks/useGetDraftsSectionPermissions';
import dilutionTypes from './dilutionTypes';
import downloadOptions from './downloadOptions';
import generateDocumentOptions from './generateDocumentOptions';
import operationOptions from './operationOptions';
import proportionalityTypes from './proportionalityTypes';

const draftType = 'DRAFT_CAPITAL_INCREASE';

const DraftCapitalIncrease = () => {
  const location = useLocation();
  const printRef = useRef();
  const { t, i18n } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId, draftId } = useParams();

  const { isAdmin, isReadOnly } = useGetDraftsSectionPermissions().data;

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const drafts = useSelector((state) => state?.drafts);
  const plans = useSelector((state) => state?.plans);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const operations = useSelector(
    (state) => state?.society?.actualSociety.operations
  );

  const [rows, setRows] = useState([]);
  const [rowsPrintVersion, setRowsPrintVersion] = useState([]);

  const [currentDraft, setCurrentDraft] = useState({});
  const [draft, setDraft] = useState({});
  const [sourceDraftId, setSourceDraftId] = useState(null);
  const [draftName, setDraftName] = useState('');
  const [isConverted, setIsConverted] = useState(false);
  const [convertedOperation, setConvertedOperation] = useState(null);
  const [displayNumerationTable, setDisplayNumerationTable] = useState(false);

  const [partners, setPartners] = useState([]);
  const [updatedPartners, setUpdatedPartners] = useState([]);

  const [temporalValuation, setTemporalValuation] = useState(0);
  const [valuation, setValuation] = useState(0); // Valoración Pre Money
  const [totalContribution, setTotalContribution] = useState(0); // Ampliación
  const [valuationPostMoney, setValuationPostMoney] = useState(0); // Valoración Post Money
  const [nominalValue, setNominalValue] = useState(0); // Valor nominal
  const [premium, setPremium] = useState(0); // Prima
  const [newSharesPrice, setNewSharesPrice] = useState(0); // Precio nuevas participaciones
  const [newShares, setNewShares] = useState(0); // Nuevas participaciones
  const [dilution, setDilution] = useState(0); // Dilución
  const [dilutionType, setDilutionType] = useState(dilutionTypes.ND);
  const [showPartnerBook, setShowPartnerBook] = useState(false);
  const [touched, setTouched] = useState(false);
  const [showAllPartners, setShowAllPartners] = useState(false);
  const [allUpdatedPartners, setAllUpdatedPartners] = useState([]);
  const [actualFrom, setActualFrom] = useState();
  const [numerationRefs, setNumerationRefs] = useState();

  const [currentDownloadOptions, setCurrentDownloadOptions] = useState(
    downloadOptions(i18n)
  );
  const [currentGenerateDocumentOptions] = useState(generateDocumentOptions());
  const [currentOperationOptions, setCurrentOperationOptions] = useState(
    operationOptions(i18n)
  );
  const [planUnits, setPlanUnits] = useState(0);

  const [summaryRow, setSummaryRow] = useState({
    totalContribution: 0,
    totalSharesNew: 0,
    totalSharesTotal: 0,
    totalNDTotal: 0,
    totalFDTotal: 0,
    totalFDPotential: 0,
  });

  const [allSummaryRow, setAllSummaryRow] = useState({
    totalContribution: 0,
    totalSharesNew: 0,
    totalSharesTotal: 0,
    totalNDTotal: 0,
    totalFDTotal: 0,
    totalFDPotential: 0,
  });

  const previousUpdatedPartners = useRef(updatedPartners);
  const previousActualSociety = useRef(actualSociety);

  const buttons = {
    equity: 'equityBtn',
    summary: 'summaryBtn',
    original: 'tableBtn',
  };

  const handleExportTable = (button) => {
    const btn = document.getElementById(button);
    if (operations.length > 0) {
      if (button === buttons.equity && updatedPartners.length > 0) {
        btn.click();
      } else if (
        button === buttons.summary &&
        partners.length > 0 &&
        plans &&
        holdingClasses.length > 0
      ) {
        btn.click();
      } else if (button === buttons.original) {
        btn.click();
      }
    }
  };

  const filterOperationOptions = (operationOptions) => {
    const options = cloneDeep(operationOptions);
    const filteredOptions = options?.map((option, index) => {
      if (index === 0) {
        return !isConverted || !convertedOperation
          ? { ...option, disabled: true }
          : option;
      }
      if (index === 1) {
        return isConverted ? { ...option, disabled: true } : option;
      }
      return !draftId ? { ...option, disabled: true } : option;
    });
    return filteredOptions;
  };

  const downloadFileName = `${draftName || t('CapitalIncrease')} - ${
    actualSociety?.name
  }`;

  const handleSaveDraft = () => {
    if (draftId) {
      dispatch(updateDraft(draftId, draft));
      setTouched(false);
    } else {
      dispatch(
        setModal(<SaveDrafts draftData={draft} setTouched={setTouched} />)
      );
    }
  };

  const addPartnerToDraft = (partner) => {
    setPartners([...partners, partner]);
  };

  const updatePartnerToDraft = (partner) => {
    const initialPartners = [...updatedPartners];
    const newPartners = initialPartners?.map((p) =>
      p?.generatedKey === partner?.generatedKey ? partner : p
    );
    setPartners([...newPartners]);
  };

  const getInitialPartnerData = (partnersData) =>
    partnersData?.map((partner) => ({
      ...partner,
      contribution: partner?.realContribution || partner?.contribution,
      proportionality: partner?.isSharesProportional
        ? proportionalityTypes.ND
        : partner?.proportionality,
      generatedKey: uuidv4(),
      shares: {
        initial: partner?.societyPartnerId
          ? getPartnerActualShares(partner?.societyPartnerId, actualSociety) ||
            0
          : 0,
        future: partner?.societyPartnerId
          ? getPartnerFutureShares(partner?.societyPartnerId, actualSociety) ||
            0
          : getInvestorShares(partner?.cif, actualSociety) || 0,
      },
    }));

  const getTotalNewShares = (partnersData) =>
    partnersData.map((item) => item.shares?.new).reduce((a, b) => a + b, 0);

  const getTotalContribution = (partnersData) =>
    partnersData.map((item) => item.contribution).reduce((a, b) => a + b, 0);

  const getActualDraft = async (draftId) =>
    drafts.find((draft) => draft['_id'] === draftId);

  const calculatePartnersData = (partnersList) => {
    const currentFrom = { ...actualFrom };
    const updatedPartners = [];

    const partnersProportional = [];
    const partnersNotProportional = [];

    const config = {};
    const math = create(all, config);

    partnersList.forEach((partner) => {
      if (partner?.proportionality) {
        partnersProportional.push(partner);
      } else {
        partnersNotProportional.push(partner);
      }
    });

    const cleanValueValuation = cleanValue(valuation);
    const societyTotalShares = getSocietyTotalShares(actualSociety);
    const societyActualShares = getSocietyActualShares(actualSociety);
    const societyPotentialShares =
      actualSociety.sharesCount?.actual +
      (actualSociety.sharesCount?.drafts || 0) +
      planUnits;
    const societyShares =
      // eslint-disable-next-line no-nested-ternary
      dilutionType === dilutionTypes.ND
        ? societyActualShares
        : dilutionType === dilutionTypes.FD
        ? societyTotalShares
        : societyPotentialShares;
    const currentPremium =
      cleanValueValuation / societyShares - (nominalValue || 0);
    setPremium(currentPremium);
    const currentSharesPrice = currentPremium + nominalValue;

    const totalPartnersNotProportionalShares = partnersNotProportional.length
      ? partnersNotProportional.reduce((acc, partner) => {
          const cleanPartnerDiscountPercent = cleanValue(
            partner?.discountPercent || 0
          );

          const discountTotal = partner.discountByValuation
            ? cleanValueValuation * (cleanPartnerDiscountPercent / 100)
            : currentSharesPrice * (cleanPartnerDiscountPercent / 100);

          const currentContribution =
            partner?.realContribution || partner.contribution;

          let partnerNewShares;

          if (partner.discountByValuation) {
            partnerNewShares = currentContribution
              ? Math.floor(
                  new Decimal(currentContribution)
                    .div(
                      new Decimal(cleanValueValuation - discountTotal).div(
                        societyShares
                      )
                    )
                    .toNumber()
                )
              : 0;
          } else {
            partnerNewShares = currentContribution
              ? Math.floor(
                  new Decimal(currentContribution)
                    .div(new Decimal(currentSharesPrice - discountTotal))
                    .toNumber()
                )
              : 0;
          }
          return acc + partnerNewShares;
        }, 0)
      : 0;

    const societyCounts = {
      ND: societyActualShares,
      FD: societyTotalShares,
      POTENTIAL: societyPotentialShares,
      CUSTOM_ND: societyActualShares,
      CUSTOM_FD: societyTotalShares,
      CUSTOM_POTENTIAL: societyPotentialShares,
    };

    const partnersProportionalSystemOfEquations = partnersProportional.reduce(
      (acc, partner, index) => {
        const NDInitial =
          (partner?.shares?.initial * 100) / societyActualShares / 100;
        let percentDecimal = NDInitial;
        if (
          partner?.proportionality === proportionalityTypes.CUSTOM_ND ||
          partner?.proportionality === proportionalityTypes.CUSTOM_FD ||
          partner?.proportionality === proportionalityTypes.CUSTOM_POTENTIAL
        ) {
          percentDecimal = partner?.proportionalityPercent / 100;
        }
        const societyCoef =
          (societyCounts?.[partner?.proportionality] +
            totalPartnersNotProportionalShares) *
          percentDecimal;
        const partnerCoef =
          partner?.proportionality === proportionalityTypes.ND ||
          partner?.proportionality === proportionalityTypes.CUSTOM_ND
            ? partner?.shares?.initial
            : partner?.shares?.future;

        const leftCoefs = partnersProportional.map((partner, i) =>
          index === i ? 1 - percentDecimal : -percentDecimal
        );
        acc.U.push(leftCoefs);
        acc.b.push(societyCoef - partnerCoef);
        return acc;
      },
      { U: [], b: [] }
    );

    let partnersProportionalUpdated = [];
    if (partnersProportional.length) {
      const solvedSystem = math.lusolve(
        partnersProportionalSystemOfEquations.U,
        partnersProportionalSystemOfEquations.b
      );
      const partnersProportionalWithSharesDistributed =
        partnersProportional.map((partner, index) => {
          const newShares = solvedSystem?.[index]?.[0];
          return {
            ...partner,
            newShares: Math.round(newShares),
          };
        });
      partnersProportionalUpdated =
        partnersProportionalWithSharesDistributed.map((partner) => ({
          ...partner,
          contribution: partner.newShares * currentSharesPrice,
          realContribution: partner.newShares * currentSharesPrice,
        }));
    }
    const finalPartnersList = [
      ...partnersNotProportional,
      ...partnersProportionalUpdated,
    ];

    finalPartnersList?.forEach((partner) => {
      const holdingClass =
        partner?.holdingClass || holdingClasses?.[0]?.['_id'];
      const holdingClassName = holdingClasses?.find(
        (currentClass) => currentClass['_id'] === holdingClass
      )?.name;
      const cleanValueValuation = cleanValue(valuation);
      const cleanPartnerDiscountPercent = cleanValue(
        partner?.discountPercent || 0
      );
      const discountTotal = partner.discountByValuation
        ? cleanValueValuation * (cleanPartnerDiscountPercent / 100)
        : currentSharesPrice * (cleanPartnerDiscountPercent / 100);

      let partnerNewShares;
      const currentContribution =
        partner?.realContribution || partner.contribution;

      if (partner.discountByValuation) {
        partnerNewShares = currentContribution
          ? Math.floor(
              new Decimal(currentContribution)
                .div(
                  new Decimal(cleanValueValuation - discountTotal).div(
                    societyShares
                  )
                )
                .toNumber()
            )
          : 0;
      } else {
        partnerNewShares = currentContribution
          ? Math.floor(
              new Decimal(currentContribution)
                .div(new Decimal(currentSharesPrice - discountTotal))
                .toNumber()
            )
          : 0;
      }

      const partnerTotalShares = partnerNewShares + partner?.shares?.initial;

      const partnerTotalFutureShares =
        partnerNewShares + partner?.shares?.future;

      const partnerContribution = partner?.contributionNotRound
        ? currentContribution
        : partnerNewShares *
          ((cleanValueValuation - discountTotal) / societyShares);

      const NDInitial = (partner?.shares?.initial * 100) / societyActualShares;
      updatedPartners.push({
        ...partner,
        contribution: partnerContribution || 0,
        realContribution: partner?.realContribution || 0,
        discountPercent: partner?.discountPercent || 0,
        discountByValuation: partner?.discountByValuation,
        discountTotal,
        NDInitial,
        shares: {
          ...partner.shares,
          new: partnerNewShares,
          total: partnerTotalShares,
          totalFuture: partnerTotalFutureShares,
        },
        shareFrom: partner?.contribution
          ? currentFrom?.[numerationRefs?.[holdingClass]]
          : '',
        shareTo: partner?.contribution
          ? currentFrom?.[numerationRefs?.[holdingClass]] + partnerNewShares - 1
          : '',
        nominal: partnerContribution
          ? partnerNewShares * actualSociety?.nominalValue
          : 0,
        premium: partner?.contributionNotRound
          ? partnerNewShares *
            ((partnerContribution || 0) / partnerNewShares - nominalValue)
          : partnerNewShares *
            ((cleanValueValuation - (discountTotal || 0)) / societyShares -
              nominalValue),
        premiumByShare: partner?.contributionNotRound
          ? (partnerContribution || 0) / partnerNewShares - nominalValue
          : (cleanValueValuation - (discountTotal || 0)) / societyShares -
            nominalValue,
        className: partnerContribution ? holdingClassName : '',
      });
      if (partner?.contribution) {
        currentFrom[numerationRefs[holdingClass]] += partnerNewShares;
      }
      setNewShares(getTotalNewShares(updatedPartners));
    });

    const accumulatedTotalShares =
      societyTotalShares + getTotalNewShares(updatedPartners);

    const accumulatedActualShares =
      societyActualShares + getTotalNewShares(updatedPartners);

    const accumulatedPotentialShares =
      societyPotentialShares + getTotalNewShares(updatedPartners);

    const updatedNDPartners = updatedPartners?.map((partner) => ({
      ...partner,
      NDTotal: (partner.shares.total * 100) / accumulatedActualShares,
      NDPercent:
        (partner.shares.total * 100) / accumulatedActualShares -
        partner.NDInitial,
      FDPotential:
        (partner.shares.totalFuture * 100) / accumulatedPotentialShares,
      FDTotal: (partner.shares.totalFuture * 100) / accumulatedTotalShares,
    }));

    if (showAllPartners) {
      const NDTotals = updatedNDPartners.map((partner) => partner.NDTotal || 0);
      const roundTotals = percentRound(NDTotals, 2);
      for (let i = 0; i < updatedNDPartners.length; i += 1) {
        updatedNDPartners[i].NDTotal = roundTotals[i] || 0;
      }
    }

    let newUpdatedPartners = updatedNDPartners;
    if (plans?.length === 1) {
      // Add dilution values for updatedPartners
      const updatedDilutionPartners = updatedNDPartners.map((partner) => {
        const societyPartner = actualSociety?.partners?.find(
          (p) => p?.['_id'] === partner?.societyPartnerId
        );
        if (societyPartner) {
          const hasDilution = societyPartner?.sharesCount?.hasDilution;
          const newSharesCount = {
            ...partner?.shares,
            dilutedFuture: hasDilution
              ? partner?.shares?.totalFuture
              : partner?.shares?.totalFuture -
                societyPartner?.sharesCount?.future,
          };
          return {
            ...partner,
            hasDilution,
            shares: newSharesCount,
          };
        }
        const newSharesCount = {
          ...partner?.shares,
          dilutedFuture: partner?.shares?.totalFuture,
        };
        return { ...partner, hasDilution: true, shares: newSharesCount };
      });
      // Add partners from society who are not in the draft
      const allPartners = [...updatedDilutionPartners];
      actualSociety?.partners?.forEach((partner) => {
        const investor = allPartners.find(
          (investorPartner) =>
            investorPartner?.societyPartnerId === partner['_id']
        );
        if (!investor) {
          const partnerFutureShares =
            partner?.sharesCount?.actual +
            partner?.sharesCount?.future +
            partner?.sharesCount?.drafts;
          const hasDilution = partner?.sharesCount?.hasDilution;
          allPartners.push({
            ...partner,
            hasDilution,
            shares: {
              initial: partner?.sharesCount?.actual,
              future: partnerFutureShares,
              dilutedFuture: hasDilution
                ? partnerFutureShares
                : partnerFutureShares - partner?.sharesCount?.future,
              total: partner?.sharesCount?.actual,
              totalFuture: partnerFutureShares,
            },
            FDTotal: (partnerFutureShares * 100) / accumulatedTotalShares,
            FDPotential:
              (partnerFutureShares * 100) / accumulatedPotentialShares,
          });
        }
      });
      // Calculate totalFD and totalPotentialFD assuming that all partners dilutes
      const totals = allPartners.reduce(
        (acc, curr) => {
          acc.totalFD += curr?.FDTotal;
          acc.totalPotential += curr?.FDPotential;
          return acc;
        },
        { totalFD: 0, totalPotential: 0 }
      );

      const totalDilutionPercents = allPartners.reduce(
        (acc, curr) => {
          const currSocietySharesCount = curr?.hasDilution
            ? accumulatedTotalShares
            : accumulatedTotalShares - actualSociety?.sharesCount?.future;
          const currSocietyPotentialSharesCount = curr?.hasDilution
            ? accumulatedPotentialShares
            : accumulatedPotentialShares - planUnits;
          acc.totalDilutionFD +=
            (curr?.shares?.dilutedFuture * 100) / currSocietySharesCount;
          acc.totalDilutionPotential +=
            (curr?.shares?.dilutedFuture * 100) /
            currSocietyPotentialSharesCount;
          return acc;
        },
        {
          totalDilutionFD: 0,
          totalDilutionPotential: 0,
        }
      );
      const { totalFD, totalPotential } = totals;
      const { totalDilutionFD, totalDilutionPotential } = totalDilutionPercents;

      const biasFD = Math.abs(totalDilutionFD - totalFD);
      const biasPotential = Math.abs(totalDilutionPotential - totalPotential);
      const dilutedPartnerCount = allPartners.reduce(
        (acc, curr) => (curr?.hasDilution ? acc + 1 : acc),
        0
      );
      const dilutionFDFactor = biasFD / dilutedPartnerCount;
      const dilutionPotentialFactor = biasPotential / dilutedPartnerCount;

      newUpdatedPartners = updatedDilutionPartners.map((partner) => {
        const currSocietySharesCount = partner?.hasDilution
          ? accumulatedTotalShares
          : accumulatedTotalShares - actualSociety?.sharesCount?.future;
        const currSocietyPotentialSharesCount = partner?.hasDilution
          ? accumulatedPotentialShares
          : accumulatedPotentialShares - planUnits;

        return {
          ...partner,
          FDTotal:
            (partner?.shares?.dilutedFuture * 100) / currSocietySharesCount,
          FDPotential:
            (partner?.shares?.dilutedFuture * 100) /
            currSocietyPotentialSharesCount,
        };
      }, 0);

      // Update computedFD for each partner that dilutes
      for (let i = 0; i < newUpdatedPartners.length; i += 1) {
        const currPartner = newUpdatedPartners[i];
        if (
          currPartner?.hasDilution &&
          currPartner?.FDTotal &&
          currPartner?.FDPotential
        ) {
          newUpdatedPartners[i].FDTotal -= dilutionFDFactor;
          newUpdatedPartners[i].FDPotential -= dilutionPotentialFactor;
        }
      }
    }
    const totalNewContribution = getTotalContribution(updatedPartners);
    setTotalContribution(totalNewContribution);

    // APP VERSION (% dilution = sum of all NDPercent)
    /*
    const totalDilution = updatedNDPartners
      .filter((partner) => partner?.contribution > 0)
      .reduce((a, b) => a + b.NDPercent, 0);
    */

    // FERRAN VERSION (% dilution = valuation / total contribution )
    /*
    const totalDilution = (totalNewContribution * 100) / cleanValue(valuation);
    setDilution(totalDilution);
    */

    // MARTI VERSION
    const totalNewShares = getTotalNewShares(updatedPartners);
    const totalDilution = (totalNewShares / accumulatedActualShares) * 100;
    setDilution(totalDilution);

    return newUpdatedPartners;
  };

  const getMergedPartners = (partnersArray, forRows) => {
    const { mergedShares, mergedTotals } = updatedPartners.reduce(
      (acc, cur) => {
        if (cur.societyPartnerId) {
          acc.mergedShares[cur.societyPartnerId] =
            (acc.mergedShares[cur.societyPartnerId] || 0) + cur?.shares?.new;
          acc.mergedTotals[cur.societyPartnerId] =
            acc.mergedTotals[cur.societyPartnerId] + cur?.shares?.new ||
            cur?.shares?.total;
        }
        return acc;
      },
      { mergedShares: {}, mergedTotals: {} }
    );

    const accumulatedTotalShares =
      getSocietyTotalShares(actualSociety) + getTotalNewShares(updatedPartners);

    const accumulatedActualShares =
      getSocietyActualShares(actualSociety) +
      getTotalNewShares(updatedPartners);

    const accumulatedPotentialShares =
      actualSociety?.sharesCount?.actual +
      actualSociety?.sharesCount?.drafts +
      getTotalNewShares(updatedPartners) +
      planUnits;

    const mergedPartners = partnersArray?.map((partner) => {
      const id = partner.societyPartnerId;
      return {
        ...partner,
        NDTotal:
          ((mergedTotals[id] || partner.shares.total) * 100) /
          accumulatedActualShares,
        NDPercent:
          ((mergedTotals[id] || partner.shares.total) * 100) /
            accumulatedActualShares -
          partner.NDInitial,
        FDPotential:
          ((mergedTotals[id]
            ? partner.shares.future + mergedShares[id]
            : partner.shares.totalFuture) *
            100) /
          accumulatedPotentialShares,
        FDTotal:
          ((mergedTotals[id]
            ? partner.shares.future + mergedShares[id]
            : partner.shares.totalFuture) *
            100) /
          accumulatedTotalShares,
        shares: {
          ...partner.shares,
          total:
            forRows && mergedTotals[id]
              ? mergedTotals[id]
              : partner.shares.total,
        },
      };
    });
    return mergedPartners;
  };

  const loadInitialValuesWithPartners = async (draftId) => {
    try {
      const tempDraft = await getActualDraft(draftId);

      if (tempDraft) {
        let initialPartnerShares = [];
        const tempPartners = tempDraft?.draftPartners;
        setIsConverted(tempDraft?.isConverted);
        setConvertedOperation(tempDraft?.convertedOperation);
        if (tempDraft?.isConverted) {
          initialPartnerShares = tempPartners?.map((partner) => ({
            ...partner,
            generatedKey: uuidv4(),
          }));
          setNominalValue(tempDraft?.nominalValue || 0);
          setPremium(tempDraft?.premium || 0);
        } else {
          initialPartnerShares = getInitialPartnerData(tempPartners);
        }
        setDraftName(tempDraft?.name);
        setValuation(tempDraft?.valuation);
        setTemporalValuation(tempDraft?.valuation);
        setPartners(initialPartnerShares);
        setDilutionType(tempDraft?.dilutionType || dilutionTypes.ND);
        setCurrentDraft({
          ...tempDraft,
          dilutionType: tempDraft?.dilutionType || dilutionTypes.ND,
        });
      } else {
        history.push(`/borradores/${societyId}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error loading draft data', error);
    }
  };

  const importDraftInvestors = (importDraftId, classId = null) => {
    const draftToImport = drafts?.find(
      (draft) => draft['_id'] === importDraftId
    );
    if (draftToImport) {
      let draftInvestors;
      if (draftToImport?.draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE) {
        if (draftToImport.status === convertibleNoteStatus.var.NC_ACTIVE) {
          draftInvestors = actualSociety?.investors
            ?.filter((investor) => investor.draft === importDraftId)
            .map((investor) => ({
              ...investor,
              investorId: investor?.['_id'],
            }));
          setSourceDraftId(importDraftId);
        } else {
          draftInvestors = draftToImport?.draftPartners;
          setSourceDraftId(draftToImport?.sourceDraft || importDraftId);
        }
        const holdingClass = holdingClasses?.find(
          (holdingClass) => holdingClass['_id'] === classId
        );
        const newPartners = draftInvestors?.map((investor) => {
          const generatedKey = uuidv4();
          const investorShares = getInvestorShares(
            investor?.cif,
            actualSociety
          );
          return {
            ...investor,
            generatedKey,
            contribution:
              investor?.totalContribution ||
              investor?.totalPartnerContribution ||
              investor?.contribution,
            realContribution:
              investor?.totalContribution ||
              investor?.totalPartnerContribution ||
              investor?.contribution,
            discountByValuation: true,
            shares: {
              initial: investor?.societyPartnerId
                ? getPartnerActualShares(
                    investor?.societyPartnerId,
                    actualSociety
                  )
                : 0,
              future: investor?.societyPartnerId
                ? getPartnerFutureShares(
                    investor?.societyPartnerId,
                    actualSociety
                  )
                : investorShares || 0,
            },
            carriedShares: investor?.sharesCount?.drafts,
            holdingClass: classId
              ? holdingClass?.['_id']
              : holdingClasses?.[0]?.['_id'],
          };
        });
        setPartners(newPartners);
        setValuation(draftToImport?.valuation);
        setTemporalValuation(draftToImport?.valuation);
        dispatch(addAlert(alertBodyTypes.DRAFT_INCREASE_GENERATED));
      } else if (
        draftToImport?.draftType === draftTypes.DRAFT_CAPITAL_INCREASE
      ) {
        draftInvestors = draftToImport?.draftPartners;
        const newPartners = draftInvestors?.map((partner) => ({
          ...partner,
          contribution: partner?.realContribution || partner?.contribution,
          proportionality: partner?.isSharesProportional
            ? proportionalityTypes.ND
            : partner?.proportionality,
          generatedKey: uuidv4(),
          shares: {
            initial: partner?.societyPartnerId
              ? getPartnerActualShares(
                  partner?.societyPartnerId,
                  actualSociety
                ) || 0
              : 0,
            future: partner?.societyPartnerId
              ? getPartnerFutureShares(
                  partner?.societyPartnerId,
                  actualSociety
                ) || 0
              : getInvestorShares(partner?.cif, actualSociety) || 0,
          },
          isHidden: true,
        }));
        setPartners(newPartners);
        setSourceDraftId(draftToImport?.sourceDraft || importDraftId);
        dispatch(addAlert(alertBodyTypes.DRAFT_INCREASE_GENERATED));
      }
    }
  };

  const setInitialValues = () => {
    const searchParams = new URLSearchParams(location.search);
    const initialId = searchParams.get('initial');
    const classId = searchParams.get('class') || null;
    if (initialId) {
      importDraftInvestors(initialId, classId);
    } else {
      setPartners([]);
      setUpdatedPartners([]);
      setValuation(0);
      setTemporalValuation(0);
      setRows([]);
      setTotalContribution(0);
      setValuationPostMoney(0);
      setNominalValue(actualSociety?.nominalValue);
      setPremium(0);
      setNewShares(0);
      setDilution(0);
    }
  };

  const handleChangeShowAllPartners = (event) => {
    setShowAllPartners(event.target.checked);
  };

  const handleClickUpdateData = () => {
    setValuation(temporalValuation);
    if (
      cleanValue(temporalValuation) &&
      partners?.length &&
      actualSociety &&
      actualFrom
    ) {
      setUpdatedPartners(calculatePartnersData(partners));
    } else {
      setUpdatedPartners([]);
    }
  };

  const handleChangeValuation = (e) => {
    setTemporalValuation(e.target.value);
    setTouched(true);
  };

  const addAllPartnersToList = (partnerList, getAll) => {
    if (isConverted && getAll) {
      const societyPartners = partnerList?.map((partner) => ({
        name: partner?.name,
        society: actualSociety['_id'],
        contribution: 0,
        realContribution: 0,
        discountPercent: 0,
        discountByValuation: true,
        shares: {
          initial: partner?.sharesCount?.actual,
          future: getPartnerFutureShares(partner['_id'], actualSociety),
          totalFuture: getPartnerFutureShares(partner['_id'], actualSociety),
          new: 0,
          total: partner?.sharesCount?.actual,
        },
        isPartnerAlready: true,
        societyPartnerId: partner['_id'],
        cif: partner?.cif || '',
        email: partner?.email || '',
        address: partner?.address || {},
        generatedKey: uuidv4(),
        holdingClass: holdingClasses?.[0]?.['_id'],
        isAddedForcefully: true,
      }));
      const mergedPartners = getMergedPartners(societyPartners);
      setAllUpdatedPartners(mergedPartners);
    } else if (!isConverted) {
      const newPartners = [];
      partnerList.forEach((partner) => {
        const foundPartner = updatedPartners.find(
          (p) => p.societyPartnerId === partner['_id']
        );
        if (
          !foundPartner &&
          ((partner?.sharesCount?.actual > 0 && !getAll) || getAll)
        ) {
          newPartners.push({
            name: partner?.name,
            society: actualSociety['_id'],
            contribution: 0,
            realContribution: 0,
            discountPercent: 0,
            discountByValuation: true,
            shares: {
              initial: partner?.sharesCount?.actual,
              future: getPartnerFutureShares(partner['_id'], actualSociety),
            },
            isPartnerAlready: true,
            societyPartnerId: partner['_id'],
            cif: partner?.cif || '',
            email: partner?.email || '',
            address: partner?.address || {},
            generatedKey: uuidv4(),
            holdingClass: holdingClasses?.[0]?.['_id'],
            isAddedForcefully: true,
          });
        }
      });
      const newPartnersCalculation = calculatePartnersData([
        ...newPartners,
        ...updatedPartners,
      ]);
      if (!getAll) {
        setUpdatedPartners(newPartnersCalculation);
      } else {
        const mergedPartners = getMergedPartners(newPartnersCalculation);
        setAllUpdatedPartners(mergedPartners);
      }
    }
  };

  const removePartnersFromList = (partnerList) => {
    const newPartners = partnerList.filter(
      (partner) => partner.contribution !== 0
    );

    const newPartnersCalculation = calculatePartnersData(newPartners);

    setUpdatedPartners(newPartnersCalculation);
  };

  const removePartner = (key) => {
    const newPartners = updatedPartners.filter(
      (partner) => partner?.generatedKey !== key
    );
    setPartners([...newPartners]);
  };

  const handleSaveAsOperation = () => {
    const investors = updatedPartners?.filter(
      (partner) => partner?.contribution > 0
    );
    const noCifInvestors = updatedPartners.filter(
      (partner) => !partner.societyPartnerId && !partner.cif
    );
    if (!investors.length) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('IncompleteData')}</h4>`,
        html: `<h5 class="text-base m-2">${t('ForConvertToIncreaseMustBe')}`,
        confirmButtonText: t('Accept'),
        confirmButtonColor: '#6576ff',
        showCloseButton: true,
      });
    } else if (noCifInvestors.length) {
      const noCifInvestorsNames = noCifInvestors.map(
        (investor) => investor.name || ''
      );
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('IncompleteData')}</h4>`,
        html: `<h5 class="text-base m-2">${t(
          'InvalidNoCifInvestor'
        )}</h5> <div class="text-danger mb-4">${noCifInvestorsNames.join(
          '<br />'
        )}</div>`,
        confirmButtonText: t('Accept'),
        confirmButtonColor: '#6576ff',
        showCloseButton: true,
      });
    } else {
      dispatch(
        setModal(
          <DraftToCapitalIncreaseModal
            updatedPartners={updatedPartners}
            setPartners={setPartners}
            draft={draft}
            draftId={draftId}
            setTouched={setTouched}
            size={sizes.LG}
          />
        )
      );
    }
  };

  const formatOperationForScripture = () => {
    const investors = updatedPartners.map((partner) => ({
      ...partner,
      holdingClassName: holdingClasses?.find(
        (holdingClass) => holdingClass['_id'] === partner?.holdingClass
      )?.name,
    }));
    const society = actualSociety;

    const draftNewShares = updatedPartners.reduce(
      (acc, partner) => acc + partner?.shares?.new,
      0
    );

    const societyTotalShares =
      (society?.sharesCount?.actual || 0) + draftNewShares;

    return {
      ...draft,
      investors,
      society,
      totalNewShares: newShares,
      operationType: operationTypesRealNames[operationTypes.CAPITAL_INCREASE],
      sharesPrice: newSharesPrice,
      premium,
      societyTotalShares,
    };
  };

  const generateScripture = async () => {
    try {
      const templateId =
        draftTemplates[draftTypes.DRAFT_CAPITAL_INCREASE][process.env.NODE_ENV];

      const currentTemplate = await store.dispatch(getDocument(templateId));

      const templateUpdated = updateDocumentWithValues(
        currentTemplate?.editorModel,
        tags({
          partner: {},
          society: actualSociety,
          tenderOffer: {},
          tenderOfferShares: {},
          plan: {},
          beneficiary: {},
          operation: formatOperationForScripture(),
          holdingClasses,
        })
      );

      const templateModel = templateUpdated.html;
      const docHTML = cleanHtmlEntities(templateModel);

      await downloadWord(docHTML, 'Borrador Escritura Ampliación.docx');
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    setCurrentDownloadOptions(downloadOptions(i18n));
    setCurrentOperationOptions(filterOperationOptions(operationOptions(i18n)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, isConverted, convertedOperation, draftId]);

  useEffect(
    () => {
      if (allUpdatedPartners) {
        setAllSummaryRow(
          allUpdatedPartners.reduce(
            (acc, partner) => {
              acc.totalContribution += partner?.contribution;
              acc.totalSharesNew += partner?.shares?.new;
              acc.totalSharesTotal += partner?.shares?.total;
              acc.totalNDTotal += partner?.NDTotal;
              acc.totalFDTotal += partner?.FDTotal;
              acc.totalFDPotential += partner?.FDPotential;
              return acc;
            },
            {
              totalContribution: 0,
              totalSharesNew: 0,
              totalSharesTotal: 0,
              totalNDTotal: 0,
              totalFDTotal: 0,
              totalFDPotential: 0,
            }
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allUpdatedPartners]
  );

  useEffect(() => {
    if (
      actualSociety?.partners?.filter(
        (partner) => partner?.sharesCount?.actual > 0
      )?.length < MAX_PARTNERS_CAPTABLE
    ) {
      setShowPartnerBook(true);
    }
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getHoldingClasses(societyId));
      dispatch(getStockPlans(societyId));
      if (!drafts?.length) dispatch(getDrafts(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    setNominalValue(actualSociety?.nominalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (draftId) {
      loadInitialValuesWithPartners(draftId);
    } else {
      // TODO Locked temporary because of development
      setInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId, drafts, actualSociety]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (partners?.length > 0) {
      if (isConverted) {
        // TODO set updatedPartners (get className)
        const newUpdatedPartners = partners?.map((partner) => {
          const holdingClassName = holdingClasses?.find(
            (currentClass) => currentClass['_id'] === partner?.holdingClass
          )?.name;
          return {
            ...partner,
            className: holdingClassName || '',
          };
        });
        setUpdatedPartners(newUpdatedPartners);
      } else if (actualSociety && actualFrom) {
        setUpdatedPartners(calculatePartnersData(partners));
      }
    } else {
      setUpdatedPartners([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    partners,
    isConverted,
    actualFrom,
    planUnits,
    dilutionType,
    holdingClasses,
  ]);

  useEffect(
    () => {
      if (
        actualSociety &&
        updatedPartners &&
        (actualSociety?.partners?.length !==
          previousActualSociety.current?.partners?.length ||
          !_.isEqual(updatedPartners, previousUpdatedPartners.current))
      ) {
        addAllPartnersToList(actualSociety?.partners, true);
      }
      previousActualSociety.current = actualSociety;
      previousUpdatedPartners.current = updatedPartners;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedPartners, actualSociety]
  );

  useEffect(() => {
    setValuationPostMoney(cleanValue(valuation) + +totalContribution);
  }, [valuation, totalContribution]);

  useEffect(() => {
    const units = plans
      ?.filter((plan) => !plan?.isDraft)
      ?.reduce((acc, plan) => acc + plan?.sharesTotal, 0);
    setPlanUnits(units);
  }, [plans]);

  useEffect(() => {
    if (updatedPartners.length > 0) {
      const newDraft = {
        percent: +dilution,
        valuation: cleanValue(valuation),
        draftType,
        society: actualSociety['_id'],
        totalContribution,
        premium,
        nominalValue,
        dilutionType,
        sourceDraft: sourceDraftId,
        draftPartners: JSON.stringify(
          updatedPartners
            ?.filter((partner) => partner.contribution > 0)
            .map((partner) => ({
              name: partner.name,
              cif: partner?.cif,
              email: partner?.email,
              society: partner.society,
              contribution: partner.contribution,
              realContribution: partner?.realContribution,
              contributionNotRound: partner?.contributionNotRound,
              discountPercent: partner?.discountPercent || 0,
              discountByValuation: partner?.discountByValuation,
              holdingClass: partner?.holdingClass,
              societyPartnerId: partner?.societyPartnerId,
              proportionality: partner?.proportionality,
              proportionalityPercent: partner?.proportionalityPercent || 0,
              carriedShares: partner?.carriedShares || 0,
              isHidden: !!partner?.isHidden,
            }))
        ),
      };
      setDraft(newDraft);
      const mergedPartners = getMergedPartners(updatedPartners, true);
      const filteredPartners = showAllPartners
        ? mergedPartners
        : mergedPartners.filter((partner) => !partner?.isHidden);

      setRows(
        filteredPartners?.map((partner, index) => (
          <DraftCapitalIncreaseRow
            key={JSON.stringify(partner)}
            index={index + 1}
            societyId={actualSociety['_id']}
            draft={newDraft}
            partner={partner}
            premium={premium}
            valuation={valuation}
            addPartnerToDraft={addPartnerToDraft}
            updatePartnerToDraft={updatePartnerToDraft}
            setTouched={setTouched}
            removePartner={removePartner}
            isConverted={isConverted}
          />
        ))
      );
      const filteredUpdatedPartners = showAllPartners
        ? updatedPartners
        : updatedPartners.filter((partner) => !partner?.isHidden);
      setSummaryRow(
        filteredUpdatedPartners.reduce(
          (acc, partner) => {
            acc.totalContribution += partner?.contribution;
            acc.totalSharesNew += partner?.shares?.new;
            acc.totalSharesTotal += partner?.shares?.total;
            acc.totalNDTotal += partner?.NDTotal;
            acc.totalFDTotal += partner?.FDTotal;
            acc.totalFDPotential += partner?.FDPotential;
            return acc;
          },
          {
            totalContribution: 0,
            totalSharesNew: 0,
            totalSharesTotal: 0,
            totalNDTotal: 0,
            totalFDTotal: 0,
            totalFDPotential: 0,
          }
        )
      );
    } else {
      setRows([]);
      setAllUpdatedPartners([]);
      setSummaryRow({
        totalContribution: 0,
        totalSharesNew: 0,
        totalSharesTotal: 0,
        totalNDTotal: 0,
        totalFDTotal: 0,
        totalFDPotential: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPartners]);

  useEffect(() => {
    if (nominalValue && premium) setNewSharesPrice(nominalValue + premium);
  }, [nominalValue, premium]);

  useEffect(() => {
    if (showAllPartners) {
      addAllPartnersToList(actualSociety?.partners);
    } else if (updatedPartners.length > 0) {
      removePartnersFromList(updatedPartners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllPartners]);

  useEffect(() => {
    if (holdingClasses?.length && actualSociety) {
      const initial = holdingClasses?.reduce(
        (acc, currentClass) => {
          acc.from[currentClass['_id']] = 1;
          acc.refs[currentClass['_id']] =
            currentClass?.numerationOption || currentClass['_id'];
          return acc;
        },
        {
          from: {},
          refs: {},
        }
      );
      const refs = Object.entries(initial.refs).reduce((acc, [id, ref]) => {
        acc[id] = acc[initial.refs[ref]] || initial.refs[ref];
        return acc;
      }, {});

      const from = actualSociety?.shares?.reduce((acc, share) => {
        const classId = share.shareClass?.['_id'];
        if (share?.isActive && acc[refs[classId]] <= share.to)
          acc[refs[classId]] = share.to + 1;
        return acc;
      }, initial.from);

      setNumerationRefs(refs);
      setActualFrom(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingClasses, actualSociety]);

  useEffect(() => {
    setRowsPrintVersion(
      rows.map(({ props }) => (
        <DraftCapitalIncreaseRow
          print
          key={JSON.stringify(props.partner)}
          index={props.index}
          societyId={actualSociety['_id']}
          partner={props.partner}
          premium={props.premium}
          valuation={props.valuation}
          addPartnerToDraft={addPartnerToDraft}
          updatePartnerToDraft={updatePartnerToDraft}
          setTouched={setTouched}
          removePartner={removePartner}
          isConverted={isConverted}
        />
      ))
    );
  }, [rows]); // eslint-disable-line

  return actualSociety ? (
    <>
      <Prompt
        message={`${t('Hello')}\n ${t('DraftDontSaved')}`}
        when={!!draftName && touched && !isReadOnly}
      />
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head pb-2">
            <div className="nk-block-between">
              <div className="nk-block-head-content d-flex flex-column align-items-baseline flex-grow-1">
                <h3 className="nk-block-title page-title mb-0">
                  {t('CalculateIncrease')}
                  {isConverted && (
                    <span className="badge badge-pill badge-secondary ml-2">
                      {currentDraft?.convertedDate
                        ? `${t('Converted')} | ${format(
                            new Date(currentDraft?.convertedDate),
                            dateFormat
                          )}`
                        : t('Converted')}
                    </span>
                  )}
                </h3>
                <h6
                  className="sub-title text-primary text-ellipsis mt-1"
                  style={{ maxWidth: '350px' }}
                  id="draft-name"
                >
                  {draftId && draftName}
                  <Tooltip
                    anchorId="draft-name"
                    place="bottom"
                    style={{
                      zIndex: 9999, // Adjust the value as needed
                    }}
                  >
                    {draftName}
                  </Tooltip>
                </h6>
              </div>
              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <>
                          {!isConverted && (
                            <li>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                  dispatch(
                                    setModal(
                                      <AddPartnerDraftModal
                                        draftType={
                                          draftTypes.DRAFT_CAPITAL_INCREASE
                                        }
                                        addPartnerToDraft={addPartnerToDraft}
                                        updatePartnerToDraft={
                                          updatePartnerToDraft
                                        }
                                        currentDraft={draft}
                                        valuation={valuation}
                                        premium={premium}
                                        setTouched={setTouched}
                                        hasSharesProportional
                                        newSharesPrice={newSharesPrice}
                                        otherPartners={updatedPartners}
                                      />
                                    )
                                  )
                                }
                                disabled={!valuation}
                              >
                                {t('AddInvestor.FORCED')}
                              </button>
                            </li>
                          )}

                          <li className="preview-item">
                            <OperationMenuDots
                              title={t('Operations')}
                              variant="light"
                              menuOptions={currentOperationOptions}
                              params={{
                                handleSaveAsOperation,
                                convertedOperation,
                                societyId: actualSociety?.['_id'],
                                draftId,
                                outlined: true,
                                partners: updatedPartners.filter(
                                  (partner) => partner?.contribution > 0
                                ),
                              }}
                              disabled={isReadOnly}
                            />
                          </li>

                          {/* {!isConverted && (
                            <li>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSaveAsOperation}
                                disabled={
                                  updatedPartners.length === 0 || !valuation
                                }
                              >
                                {t('SaveAsOperation')}
                              </button>
                            </li>
                          )}
                          {isConverted && convertedOperation && (
                            <Link
                              to={`/detalle-transacciones/${actualSociety?.['_id']}/${convertedOperation}`}
                              className="btn btn-white btn-outline-light"
                            >
                              {t('GoToOperation')}
                            </Link>
                          )} */}

                          <li className="preview-item">
                            <OperationMenuDots
                              title={t('GenerateDocument')}
                              variant="light"
                              menuOptions={currentGenerateDocumentOptions}
                              disabled
                              params={{
                                generateScripture,
                                outlined: true,
                              }}
                            />
                          </li>

                          <li className="preview-item">
                            <OperationMenuDots
                              title={t('Download')}
                              variant="light"
                              menuOptions={currentDownloadOptions}
                              params={{
                                handleExportTable,
                                buttons,
                                outlined: true,
                              }}
                            />
                          </li>
                          <li>
                            <PrintButton
                              fileName={downloadFileName}
                              printRef={printRef}
                              hideLabel
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* HEADER */}
          <div ref={printRef} id="margin-print-only">
            <PrintHeader title={t('PrintHeaderDraftCapitalIncrease')} />

            <div className="card card-bordered sp-plan">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="sp-plan-info card-inner pb-0">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label mr-2"
                            htmlFor="default-01"
                          >
                            {t('Assessment')}
                          </label>
                          <div className="form-control-wrap d-flex">
                            <div className="form-control-wrap">
                              <div className="form-icon form-icon-right z-index-1">
                                <em className="icon ni ni-sign-eur" />
                              </div>
                              <NumberFormat
                                id="outlined-normal"
                                className="form-control form-control-outlined"
                                value={temporalValuation}
                                onChange={handleChangeValuation}
                                isAllowed={(inputObj) => {
                                  const { floatValue, formattedValue } =
                                    inputObj;
                                  if (formattedValue === '') return true;
                                  if (
                                    floatValue >= 1 &&
                                    Number.isInteger(floatValue)
                                  )
                                    return true;
                                  return false;
                                }}
                                disabled={isConverted}
                                {...numberFormatDecimals}
                                style={{
                                  borderTopRightRadius: '0',
                                  borderBottomRightRadius: '0',
                                }}
                              />
                            </div>
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={handleClickUpdateData}
                              disabled={valuation === temporalValuation}
                              style={{
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderLeftWidth: '0',
                              }}
                            >
                              {t('Update')}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label
                            className="form-label mb-3"
                            htmlFor="full-name"
                          >
                            {t('ValuationType')}
                          </label>

                          <div className="d-flex justify-content-between">
                            <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="btnRadio"
                                id="btnRadio1"
                                value={dilutionTypes.ND}
                                checked={dilutionType === dilutionTypes.ND}
                                disabled={isConverted}
                                onChange={(event) => {
                                  setDilutionType(event.target.value);
                                  setTouched(true);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="btnRadio1"
                                id="valuation-nd"
                              >
                                {t('ND')}
                              </label>
                              <Tooltip anchorId="valuation-nd" place="top">
                                {t('ValuationNDTooltip')}
                              </Tooltip>
                            </div>
                            <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="btnRadio"
                                id="btnRadio2"
                                value={dilutionTypes.FD}
                                checked={dilutionType === dilutionTypes.FD}
                                disabled={isConverted}
                                onChange={(event) => {
                                  setDilutionType(event.target.value);
                                  setTouched(true);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="btnRadio2"
                                id="valuation-fd"
                              >
                                {t('ActualD')}
                              </label>
                              <Tooltip anchorId="valuation-fd" place="top">
                                {t('ValuationFDTooltip')}
                              </Tooltip>
                            </div>
                            <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="btnRadio"
                                id="btnRadio3"
                                value={dilutionTypes.POTENTIAL}
                                checked={
                                  dilutionType === dilutionTypes.POTENTIAL
                                }
                                disabled={isConverted}
                                onChange={(event) => {
                                  setDilutionType(event.target.value);
                                  setTouched(true);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="btnRadio3"
                                id="valuation-potential-fd"
                              >
                                {t('FD')}
                              </label>
                              <Tooltip
                                anchorId="valuation-potential-fd"
                                place="top"
                              >
                                {t('ValuationPotentialFDTooltip')}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sp-plan-desc card-inner">
                    <ul className="row gx-1 text-left align-items-end">
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">
                            {t('PreMoneyValuation')}
                          </span>
                          <NumberFormat
                            value={valuation}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                        <p>
                          <span className="text-soft">
                            {t('PostMoneyValuation')}
                          </span>
                          <NumberFormat
                            value={valuationPostMoney}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                      </li>

                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">
                            {t('PriceNewShare')}
                          </span>
                          <NumberFormat
                            value={newSharesPrice}
                            displayType="text"
                            {...currencyFormatDecimals}
                            decimalScale={7}
                          />
                        </p>
                        <p>
                          <span className="text-soft">{t('PrimeBySh')}</span>
                          <NumberFormat
                            value={premium}
                            displayType="text"
                            {...currencyFormatDecimals}
                            decimalScale={7}
                          />
                        </p>
                      </li>
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">{t('NominalValue')}</span>
                          <NumberFormat
                            value={+nominalValue?.toFixed(7)}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </p>
                        <p>
                          <span className="text-soft">{t('NewSh')}</span>
                          <NumberFormat
                            value={newShares}
                            displayType="text"
                            {...numberFormat}
                          />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="sp-plan-action card-inner">
                    <div className="pricing-head w-100">
                      <div className="pricing-title mb-4">
                        <h4 className="card-title title text-center">
                          {t('Valuation')}:
                          <br />
                          <NumberFormat
                            value={valuation}
                            displayType="text"
                            {...currencyFormat}
                          />
                        </h4>
                      </div>
                      <div className="card-text mb-4">
                        <div className="d-flex flex-row flex-wrap justify-content-around">
                          <div className="text-center mb-2">
                            <span className="h5 fw-500">
                              <NumberFormat
                                value={dilution}
                                displayType="text"
                                suffix="%"
                                {...numberFormatDecimals}
                              />
                            </span>
                            <span className="sub-text">
                              {t('PercentDilution')}
                            </span>
                          </div>
                          <div className="text-center mb-2">
                            <span className="h5 fw-500">
                              <NumberFormat
                                value={totalContribution}
                                displayType="text"
                                {...currencyFormatDecimals}
                              />
                            </span>
                            <span className="sub-text">{t('IncrAmount')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nk-block-head-content">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSaveDraft}
                        disabled={
                          updatedPartners.length === 0 ||
                          !valuation ||
                          !touched ||
                          isConverted ||
                          isReadOnly
                        }
                      >
                        {t('SaveDraft')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* LIST */}
            <div className="nk-block-head-content d-flex flex-row justify-content-end screen-only mb-0">
              <div className="form-group mt-2 mr-5 flex-grow-0 mb-0">
                <div className="custom-control custom-control-xs custom-checkbox">
                  <input
                    type="checkbox"
                    id="show-numerations"
                    className="custom-control-input"
                    value={displayNumerationTable}
                    checked={displayNumerationTable}
                    onChange={(event) =>
                      setDisplayNumerationTable(event.target.checked)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="show-numerations"
                  >
                    {t('SeeNumerationAndPrimes')}
                  </label>
                </div>
              </div>

              {!isConverted && (
                <div className="form-group mt-2 flex-grow-0 mb-0">
                  <div className="custom-control custom-control-xs custom-checkbox">
                    <input
                      type="checkbox"
                      id="show-partners"
                      className="custom-control-input"
                      value={showAllPartners}
                      checked={showAllPartners}
                      onChange={handleChangeShowAllPartners}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="show-partners"
                    >
                      {t('ShowAllPartners')}
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="invest-field form-group mt-2">
              <div className="nk-block nk-block-lg">
                <div className="card card-bordered card-preview">
                  {/* ORIGINAL DRAFT TABLE */}
                  <table
                    className={
                      displayNumerationTable
                        ? 'is-compact datatable-init nk-tb-list nk-tb-ulist d-none'
                        : 'is-compact datatable-init nk-tb-list nk-tb-ulist'
                    }
                    data-auto-responsive="false"
                  >
                    <thead>
                      <tr className="nk-tb-item nk-tb-head">
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">
                            {t('Investor.FORCED')}
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('Contribution')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('Discount')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('VariationND')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('ND')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('ActualD')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('FD')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('NewSh')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('TotalSh')}</span>
                        </th>
                        {!isConverted && (
                          <th className="nk-tb-col nk-tb-col-tools text-right" />
                        )}
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                    {/* Add a footer with summary of each column */}
                    <tfoot className=" tb-tnx-head border-top">
                      <tr className="nk-tb-item nk-tb-head">
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">Total</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalContribution}
                              displayType="text"
                              {...currencyFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text" />
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text" />
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalNDTotal}
                              displayType="text"
                              suffix="%"
                              {...numberFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalFDTotal}
                              displayType="text"
                              suffix="%"
                              {...numberFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalFDPotential}
                              displayType="text"
                              suffix="%"
                              {...numberFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text  text-right">
                            <NumberFormat
                              value={summaryRow?.totalSharesNew}
                              displayType="text"
                              {...numberFormat}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text  text-right">
                            <NumberFormat
                              value={summaryRow?.totalSharesTotal}
                              displayType="text"
                              {...numberFormat}
                            />
                          </span>
                        </th>
                        {!isConverted && (
                          <th className="nk-tb-col nk-tb-col-tools text-right" />
                        )}
                      </tr>
                    </tfoot>
                  </table>

                  {/* PRINT VERSION DRAFT TABLE */}
                  <table
                    className="is-compact datatable-init nk-tb-list nk-tb-ulist d-none"
                    data-auto-responsive="false"
                    id="capital-increase-original-table"
                  >
                    <thead>
                      <tr className="nk-tb-item nk-tb-head">
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Investor')}</span>
                        </th>
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">{t('Email')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('Contribution')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('Discount')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('Variation')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('ND')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('ActualD')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('FD')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text">{t('NewSh')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text">{t('TotalSh')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{rowsPrintVersion}</tbody>
                    {/* Add a footer with summary of each column */}
                    <tfoot className=" tb-tnx-head border-top">
                      <tr className="nk-tb-item nk-tb-head">
                        <th className="nk-tb-col text-center">
                          <span className="sub-text">Total</span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalContribution}
                              displayType="text"
                              {...currencyFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text" />
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text" />
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalNDTotal}
                              displayType="text"
                              suffix="%"
                              {...numberFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalFDTotal}
                              displayType="text"
                              suffix="%"
                              {...numberFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text text-right">
                            <NumberFormat
                              value={summaryRow?.totalFDPotential}
                              displayType="text"
                              suffix="%"
                              {...numberFormatDecimals}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-center">
                          <span className="sub-text  text-right">
                            <NumberFormat
                              value={summaryRow?.totalSharesNew}
                              displayType="text"
                              {...numberFormat}
                            />
                          </span>
                        </th>
                        <th className="nk-tb-col tb-col-md text-center">
                          <span className="sub-text  text-right">
                            <NumberFormat
                              value={summaryRow?.totalSharesTotal}
                              displayType="text"
                              {...numberFormat}
                            />
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>

                  <table
                    className={
                      displayNumerationTable
                        ? 'datatable-init nk-tb-list nk-tb-ulist'
                        : 'datatable-init nk-tb-list nk-tb-ulist d-none'
                    }
                    data-auto-responsive="false"
                    id="capital-increase-numeration-table"
                  >
                    <tr className="nk-tb-item nk-tb-head">
                      <th className="nk-tb-col text-center">
                        <span className="sub-text">{t('Partner')}</span>
                      </th>
                      <th className="nk-tb-col tb-col-md text-center">
                        <span className="sub-text">{t('Contribution')}</span>
                      </th>
                      <th className="nk-tb-col tb-col-md text-center">
                        <span className="sub-text">{t('Shares')}</span>
                      </th>
                      <th className="nk-tb-col tb-col-xl text-center">
                        <span className="sub-text">{t('Nominal')}</span>
                      </th>
                      <th className="nk-tb-col tb-col-xl text-center">
                        <span className="sub-text">{t('Premium')}</span>
                      </th>
                      <th className="nk-tb-col tb-col-xl text-center">
                        <span className="sub-text">{t('PrimeByShare')}</span>
                      </th>
                      <th className="nk-tb-col tb-col-md text-center">
                        <span className="sub-text">
                          {t('NumerationOfShares')}
                        </span>
                      </th>
                    </tr>

                    {updatedPartners?.map((partner) => (
                      <tr
                        className="nk-tb-item cursor-pointer"
                        onClick={() =>
                          dispatch(
                            setModal(
                              <AddPartnerDraftModal
                                draftType={draftTypes.DRAFT_CAPITAL_INCREASE}
                                addPartnerToDraft={addPartnerToDraft}
                                updatePartnerToDraft={updatePartnerToDraft}
                                currentDraft={draft}
                                valuation={valuation}
                                premium={premium}
                                action="EDIT"
                                partnerData={partner}
                                setTouched={setTouched}
                                hasSharesProportional
                                newSharesPrice={newSharesPrice}
                                otherPartners={updatedPartners}
                              />
                            )
                          )
                        }
                      >
                        <td className="nk-tb-col">
                          <div className="user-card">
                            <div className="user-info">
                              <span className="tb-lead">
                                {partner?.name}&nbsp;
                                <span className="dot dot-success d-md-none ml-1" />
                              </span>
                              <span>{partner?.email}</span>
                            </div>
                          </div>
                        </td>
                        <td className="nk-tb-col tb-col-md text-right">
                          <span className="tb-amount">
                            <NumberFormat
                              value={partner?.contribution}
                              displayType="text"
                              {...currencyFormatDecimals}
                            />
                          </span>
                        </td>
                        <td className="nk-tb-col tb-col-md text-right tb-amount">
                          <span>{partner?.shares?.new || 0}</span>
                        </td>
                        <td className="nk-tb-col tb-col-xl text-right">
                          <span className="tb-amount">
                            <NumberFormat
                              value={partner?.nominal}
                              displayType="text"
                              {...currencyFormatDecimals}
                            />
                          </span>
                        </td>
                        <td className="nk-tb-col tb-col-xl text-right">
                          <span className="tb-amount">
                            <NumberFormat
                              value={partner?.premium}
                              displayType="text"
                              {...currencyFormatDecimals}
                            />
                          </span>
                        </td>
                        <td className="nk-tb-col tb-col-xl text-right">
                          <span className="tb-amount">
                            <NumberFormat
                              value={partner?.premiumByShare}
                              displayType="text"
                              {...currencyFormatDecimals}
                              decimalScale={7}
                            />
                          </span>
                        </td>
                        <td className="nk-tb-col tb-col-md text-center">
                          <span className="tb-amount">
                            {partner?.contribution ? (
                              <>
                                <NumberFormat
                                  value={partner?.shareFrom}
                                  displayType="text"
                                  {...numberFormat}
                                />
                                &nbsp;-&nbsp;
                                <NumberFormat
                                  value={partner?.shareTo}
                                  displayType="text"
                                  renderText={(value) => (
                                    <span>{`${value} (${partner?.className})`}</span>
                                  )}
                                  {...numberFormat}
                                />
                              </>
                            ) : (
                              '-'
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>

                {showPartnerBook ? (
                  <div className="nk-block nk-block-embeded">
                    <PartnerBook
                      rows={rows}
                      updatedPartners={allUpdatedPartners}
                      summaryRow={allSummaryRow}
                      draftView
                    />
                  </div>
                ) : (
                  <DraftBanner setShowPartnerBook={setShowPartnerBook} />
                )}

                <ReactHTMLTableToExcel
                  id="tableBtn"
                  className="d-none"
                  table={
                    displayNumerationTable
                      ? 'capital-increase-numeration-table'
                      : 'capital-increase-original-table'
                  }
                  filename={downloadFileName}
                  sheet={t('CapitalIncrease')}
                  buttonText={`${t('Download')}`}
                  buttonComponent={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <button
                      type="button"
                      className="btn btn-white btn-outline-light"
                    >
                      <em className="icon ni ni-file-download" />
                    </button>
                  }
                />
                <ReactHTMLTableToExcel
                  id="equityBtn"
                  className="d-none"
                  table="partnerBookTable"
                  filename={`${t('PartnerBook')} - ${actualSociety?.name}`}
                  sheet="Libro de Socios"
                  buttonText={`${t('Download')}`}
                  buttonComponent={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <button
                      type="button"
                      className="btn btn-white btn-outline-light"
                    >
                      <em className="icon ni ni-file-download" />
                    </button>
                  }
                />
                <ReactHTMLTableToExcel
                  id="summaryBtn"
                  className="d-none"
                  table="partnerClassTable"
                  filename={`${t('Summary')} - ${actualSociety?.name}`}
                  sheet="Libro Registro de Socios"
                  buttonText={`${t('Download')}`}
                  buttonComponent={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <button
                      type="button"
                      className="btn btn-white btn-outline-light"
                    >
                      <em className="icon ni ni-file-download" />
                    </button>
                  }
                />
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default DraftCapitalIncrease;
