import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';

type DownloadLanguageModalFooterProps = {
  handleDownloadTable: () => void;
};

const DownloadLanguageModalFooter: FC<DownloadLanguageModalFooterProps> = ({
  handleDownloadTable,
}) => {
  const { t } = useTranslate();

  return (
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-lg btn-primary "
        onClick={handleDownloadTable}
      >
        {t('Download')}
      </button>
    </Modal.Footer>
  );
};

export default DownloadLanguageModalFooter;
